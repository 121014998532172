@import '../../../../common/styles/font-size';

.profile-change-password {
  background: #FCFCFD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  .change-password-header {
    padding: 28px 32px;
    & > h3 {
      @include text-24-fw-700;
    }
  }
  .change-password-form {
    padding: 24px 0px;
    .change-password-item {
      padding-left: 32px;
      display: flex;
      align-items: center;
      width: 66%;
      position: relative;
      & > p {
        @include  text-16-fw-400;
        color: #EA2127;
        width: 60%;
      }
      & > label {
        @include text-16-fw-700;
        width: 40%;
      }
      & > input {
        border: 1px solid #D0D5DD;
        border-radius: 4px;
        width: 60%;
        @include text-16-fw-400;
        outline: none;
        padding: 12px;
        &::placeholder {
          @include text-16-fw-400;
        }
      }
      & > span {
        position: absolute;
        right: 10px;
      }
    }
    .person-wrap-btn {
      padding: 16px 32px 0px 32px;
      text-align: end;
      .person-btn {
        background-color: #EA2127;
        color: #fff;
        &:hover {
          color: #EA2127;
          background-color: #fff;
          border-color: #EA2127;
        }
      }
    }
    
  }
  

}

/*Mobile styles*/
@media screen and (min-width: 320px) and (max-width: 767px) {
  .profile-change-password {
    .change-password-form {
     .change-password-item {
      width: 100%;
      padding: 0px 32px;
      & > span {
       right: 7%;
      }
     }
    
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .profile-change-password {
    .change-password-form {
     .change-password-item {
      flex-direction: column;
      & > p {
        width: 100%;
      }
      & > label {
        width: 100%;
      }
      & > input {
        width: 100%;
      }
      & > span {
        bottom: 10px;
        right: 40px;
      }
     }
    
    }
  }
}

