@import '../../../../common/styles/font-size';
// home-recognition
.home-recognition{
  // padding: 0px 72px 140px;
  margin-bottom: 250px;
  .w-1440 {
    display: flex;
    // gap: 76px;
    .recognition-text {
      margin-top: 60px;
      margin-right: 16px;
      width: 25%;
      & > h3 {
        @include text-42-fw-700;
      }
      & > p {
        @include text-16-fw-400;
      }
    }
    .recognition-poster {
      width: 100%;
    }
    // .recognition-slides {
    //   margin: auto;
    //   max-width: 936px;
    //   padding: 0px 16px;
    //   .slick-slider {
    //     .slick-list {
    //       .slick-track {
    //         .slick-slide {
    //           & > div {
    //             display: flex;
    //             justify-content: center;
    //           }
    //         }
    //       }
    //     }
    //     .slick-dots {
    //       & > li {
    //         margin-right: 8px;
    //         & > button {
    //           width: 20px;
    //           height: 4px;
    //           background-color: #d9d9d9;
    //           border-radius: 4px;
    //           padding: 0;
    //           &::before {
    //             display: none;
    //           }
    //         }
    //       }
    //       .slick-active {
    //         & > button {
    //           background: #ea2127;
    //         }
    //       }
    //     }
    //   }
  
    //   .recognition-row {
    //     max-width: 280px;
    //     height: 320px;
    //     padding: 20px 16px;
    //     background: url("../../../../assets/images/image_mask_circle.png") center
    //       center no-repeat #ffffff;
    //     background-size: cover;
    //     box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    //       0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    //     border-radius: 8px;
    //     .recognition-logo {
    //       width: 66px;
    //     }
    //     & > h3 {
    //       @include text-22-fw-600;
    //     }
    //     & > p {
    //       @include text-16-fw-400;
    //     }
    //   }
    // }
    .recognition-wrap {
      // display: flex;
      // flex-wrap: wrap;
      // gap: 21px;
      // justify-content: center;
      // align-items: center;
      // bottom: -75px;
      // right: 210px;
      margin: auto;
      max-width: 800px;
      width: 75%;
      
      .slick-slider {
        .slick-list {
          .slick-track {
            .slick-slide {
              & > div {
                display: flex;
                justify-content: center;
              }
            }
          }
        }
        .slick-dots {
          & > li {
            margin-right: 8px;
            & > button {
              width: 20px;
              height: 4px;
              background-color: #d9d9d9;
              border-radius: 4px;
              padding: 0;
              &::before {
                display: none;
              }
            }
          }
          .slick-active {
            & > button {
              background: #ea2127;
            }
          }
        }
      }
      // .recognition-row {
      //   flex-basis:33.33%;
      //   max-width: 249px;
      //   height: 313px;
      //   padding: 20px 12px;
      //   background: url('../../../../assets/images/image_mask_circle.png') center center no-repeat #ffffff;
      //   background-size: cover;
      //   box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
      //   border-radius: 8px;
      //   .recognition-logo{
      //     width: 66px;
      //   }
      //   & > h3 {
      //     @include text-22-fw-600;
      //   }
      //   & > p {
      //     @include text-14-fw-400;
      //   }
      // }
      .recognition-row {
        // width: 33.33%;
        max-width: 249px;
        height: 313px;
        padding: 20px 16px;
        background: url("../../../../assets/images/image_mask_circle.png") center
          center no-repeat #ffffff;
        background-size: cover;
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
          0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        border-radius: 8px;
        .recognition-logo {
          width: 66px;
        }
        & > h3 {
          @include text-22-fw-600;
        }
        & > p {
          @include text-16-fw-400;
        }
      }
    }
    .recognition-slides {
      margin: auto;
      max-width: 936px;
      padding: 0px 16px;
      .slick-slider {
        .slick-list {
          .slick-track {
            .slick-slide {
              & > div {
                display: flex;
                justify-content: center;
              }
            }
          }
        }
        .slick-dots {
          & > li {
            margin-right: 8px;
            & > button {
              width: 20px;
              height: 4px;
              background-color: #d9d9d9;
              border-radius: 4px;
              padding: 0;
              &::before {
                display: none;
              }
            }
          }
          .slick-active {
            & > button {
              background: #ea2127;
            }
          }
        }
      }
  
      .recognition-row {
        max-width: 280px;
        height: 320px;
        padding: 20px 16px;
        background: url("../../../../assets/images/image_mask_circle.png") center
          center no-repeat #ffffff;
        background-size: cover;
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
          0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        border-radius: 8px;
        .recognition-logo {
          width: 66px;
        }
        & > h3 {
          @include text-22-fw-600;
        }
        & > p {
          @include text-16-fw-400;
        }
      }
    }
  }
  
}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .home-recognition {
    .w-1440 {

      .recognition-text {
        & > h3 {
          @include text-32-fw-700;
        }
        & > p {
          @include text-14-fw-400;
        }
      }
      .recognition-wrap {
        .recognition-row {
          margin-right: 12px;
          & > h3 {
            @include text-18-fw-600;
          }
          & > p {
            @include text-14-fw-400;
          }
        }
      }
    }
  }
}