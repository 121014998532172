.test-engine-page {
  .test-engine-end {
    margin-top: 120px;
    margin-bottom: 210px;
    .w-1440 {
      .row {
        justify-content: center;
      }
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 991px) {
  .test-engine-page {
    .test-engine-end {
      margin-top: 0px;
      margin-bottom: 180px;
      .w-1440 {
        margin: auto 16px;
      }
    }
  }
}