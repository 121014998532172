@import '../../../../common/styles/font-size';

// m-device-farm-banner
.m-device-farm-banner {
  background: linear-gradient(180deg, rgba(27, 31, 37, 0) 0%, #1B1F25 16.28%);
  // background: url('../../../../assets/images/aka-test-engine/mobile_bg_banner_2.png') center center no-repeat;
  // background-size: cover;
  color: #ffffff;
  display: flex;
  flex-direction: column;
    .banner-title{
      padding: 0px 16px;
      text-align: center;
      & > h1 {
        @include text-26-fw-700;
      } 
      & > p {
        @include text-14-fw-400;
      }
      & > a {
        & > button {
            @include text-16-fw-700;
            color: #ffffff;
            border: 1px solid #EA2127;
            background: #EA2127;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            padding: 8px 12px;
            &:hover {
              background: #ffffff;
              color: #EA2127;
              border: 1px solid #EA2127;
            }
        }
      }
      & > img {
        max-width: 344px;
      }
      padding-top: 96px;
      padding-bottom: 80px;
    }
}

/*Tablet styles*/
@media screen and (min-width: 320px) and (max-width: 375px) {
  .m-device-farm-banner {
    .banner-title{
      & > img {
        max-width: 100%;
      }
      
    }
  }
}
