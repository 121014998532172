@import '../../common/styles/font-size';

.success-stories-page {
  margin: 80px auto 134px auto;
  max-width: 1172px;
  padding: 0 16px;
  & > h1 {
    @include text-44-fw-700;
  }
  & > h2 {
    @include text-32-fw-600;
  }

  & > hr {
    margin-top: 0px;
    margin-bottom: 32px;
    height: 2px;
  }

  .success-stories-header {
    display: flex;
    background: url('../../assets/images/image_graywood_grain.png') center center no-repeat, #F2F2F2;
    background-size: cover;
    border-radius: 20px;
    gap: 24px;
    padding: 40px;
    .success-stories-left{
      & > h1 {
        @include text-32-fw-600;
      }
      & > p {
        @include text-16-fw-700;
        color: #667085;
      }
      .success-stories-btn {
        button {
          @include  text-16-fw-600;
          background: #EA2127;
          border-radius: 4px;
          border: none;
          padding: 14px 20px;
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
    .success-stories-right {
      max-width: 506px;
      .image-container {
        position: relative;
        .introduction-icon{
          width: 42px;
          height: 42px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        & > img {
          border-radius: 20px;
        }
      }
      
    }
  }

  .success-stories-tab {
    display: flex;
    justify-content: space-between;
    .tab-left {
      display: flex;
      .tab-item {
        @include text-16-fw-700;
        color: #667085;
        width: 120px;
        margin: auto 0;
        text-align: center;
        cursor: pointer;
        padding-bottom: 16px;
        border-bottom: 2px solid #D0D5DD;
      }
      .tab-active{
        border-bottom: 2px solid red;
      }
    }
    .tab-right {
      flex: 1;
      border-bottom: 2px solid #D0D5DD;
      position: relative;
      .search-container {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        & > input {
          width: 100%;
          @include text-16-fw-400;
          outline: none;
          border-width: 0px;
          // border-color: #D0D5DD;
          &:focus {
            // border-color: #EA2127;
          }
          &::placeholder {
            @include text-16-fw-400;
          }
        }

        & > button {
          border: none;
          background: transparent;
          border-width: 0 0 2px;
          border-color: #D0D5DD;
        }
      
      }
      
    }
  }
  
  .success-stories-more {
    margin: 0px 0px 96px 0px;
    & > h2 {
      flex: 0 0 100%;
      @include text-32-fw-600;
    }
    .more-wrap-item {
      display: flex;
      flex-wrap: wrap;
      column-gap: 24px;
      row-gap: 68px;
      .more-item {
        max-width: 354px;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.161) 0px 1px 4px;
        & > a {
          text-decoration: none;
          color: #101828;
            & > img {
              border-top-left-radius: 20px;
              border-top-right-radius: 20px;
              width: 100%;
            }
            & > h3 , p {
              padding: 0px 10px;
            }
        }
        .more-img {
          position: relative;
          & > img {
            border-radius: 20px;
            width: 100%;
          }
          .more-icon{
            width: 42px;
            height: 42px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        & > h3 {
          @include text-18-fw-600;
        }
        .more-short-desc {
          color: #667085;
          @include text-16-fw-400;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }

      }
    }
    .search_results_content {
      padding-bottom: 116px;
      & > p {
        @include text-16-fw-400;
      }
      
    }
  }
}


/*-----------------------------------------------------*/
/*Tablet styles*/

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .success-stories-page {
    .success-stories-more {
      .more-wrap-item {
        .more-item {
          flex-basis: 31.3333%;
        }
      }
    }
  }
  
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .success-stories-page {
    .success-stories-more {
      .more-wrap-item {
        .more-item {
          flex-basis: 31.3333%;
        }
      }
    }
  }
}

/*Mobile styles*/
@media screen and (min-width: 375px) and (max-width: 991px) {
  .success-stories-page {
    .success-stories-header {
      flex-direction: column-reverse;
      padding: 16px;
      .success-stories-left{
        .divider-48 {
          margin-bottom: 20px !important;
        }
        & > h1 {
          @include text-24-fw-700;
        }
        & > p {
          @include text-14-fw-400;
        }
      }
      .success-stories-right {
        width: unset;
        margin: auto;
      }  
    }

    .success-stories-more {
      .more-wrap-item {
        justify-content: center;
      }
    }
  }
  
}