.blog_page {

  .blog_page_content{
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    .blog_page_content_wrap{
    //   display: "flex";
    //   gap: 16px;
    //  flex-wrap: wrap;
    }
    .blog_page_card_time_to_read{
      padding: 0 24px;
    }
    .blog_page_card{
      display: flex;
      flex-wrap: wrap;
      background: linear-gradient(180deg,#fdfbfb,#eaedee);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 24px 24px;
    }
  }
}

