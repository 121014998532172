
@import '../../common/styles/font-size';

.ads-page {
  .ads-page-aside {
    max-width: 1457px;
    margin: 0px auto 0px auto;
    padding: 0 16px;
    aside {
      position: fixed;
      z-index: 1;
      .is-active-li > a {
        &::after {
          // border: 2px solid red;
          // border-radius: 50%;
          // background: rgba(0,0,0,0);
          // width: 30px;
          // height: 30px;
          content:" ";
          width:20px;
          height:20px;
          // position:absolute;
          // top:50%;
          // left:50%;
          // transform:translate3d(-50%,-50%,0);
          border-radius:50%;
          background: -webkit-radial-gradient( #F97066 20% , rgba(0, 0, 0,0) 1% , rgba(0, 0, 0,0) 60% , #F97066 20% );
          /*     background: -o-radial-gradient( #F97066 20% , rgba(0, 0, 0,0) 1% , rgba(0, 0, 0,0) 60% , #F97066 20% );
      background: -moz-radial-gradient( #F97066 20% , rgba(0, 0, 0,0) 1% , rgba(0, 0, 0,0) 60% , #F97066 20% );
      background: radial-gradient( #F97066 20% , rgba(0, 0, 0,0) 1% , rgba(0, 0, 0,0) 60% , #F97066 20% );
    } */
        }
      }
      .is-collapsible > li > a {
        color: blue;
        display: block;
        width: 100%;
      }
      
      .is-collapsed > li > a {
        font-size: 0;
        transition: font-size 0.4s ease-out;
      }
      ol {
        list-style: none;
        font-size: 20px;
      }
      li > a {
        // text-decoration: none;
        // color: slategrey;
        // font-weight: bold;
      
        // transition: color .2s ease-in-out, font-size .2s ease-in-out;
        position: relative;
        display: block;
        margin: 2em 0;
        background-color: transparent;
        color: #222;
        text-align: center;
        &:after {
          // display: block;
          // padding-bottom: 15%;
          // width: 15%;
          // height: 0;
          // border-radius: 50%;
          // background-color: #D0D5DD;
          // content: "";
          content: '';
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #D0D5DD;
        }
      }
    }
  }

  main {
    .ads-wrap {
      overflow: hidden;
      position: relative;
      .ads-bg-img {
        position: absolute;
        max-width: 900px;
        right: -23%;
        top: 13%;
      }
    }
  }

  
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .ads-page {
    .ads-page-aside {
      aside {
        display: none;
      }
    }
    main {
      .ads-wrap {
        .ads-bg-img {
          display: none;
        }
      }
    }
  }
}