@import '../../../../common/styles/font-size';


  .test-management-benefit {
    margin: 30px 0px 90px 0px;
    padding: 80px 0px;
    background-color: #f2f4f7;
    .w-1440 {
      & > h2 {
        text-align: center;
        @include text-42-fw-700;
      }
      .benefit-center {
        justify-content: center;
        max-width: 954px;
        margin: auto;
        .benefit-item {
          max-width: 249px;
          padding: 20px 12px;
          background:#ffffff;
          border: 2px solid transparent;
          border-radius: 12px;
          & + article {
            margin-left: 24px;
            &:hover {
              margin: -20px 0px 20px 24px;
              transition: all 0.2s ease-in-out;
              background: 
              linear-gradient(#ffffff 0 0) padding-box, /*this is your grey background*/
              linear-gradient(90deg, rgba(0,224,108,0.32) 0%, rgba(1,201,236,0.98) 100%, rgba(0,224,108,0.32) 100%) border-box;
            }
          }
          & > h3 {
            @include text-22-fw-600;
          }
          & > p {
            @include text-14-fw-400;
          }
        }
        .benefit-item:nth-child(1) {
          &:hover {
            margin: -20px 0px 20px 0px;
            transition: all 0.2s ease-in-out;
            background: 
            linear-gradient(#ffffff 0 0) padding-box, /*this is your grey background*/
            linear-gradient(90deg, rgba(0,224,108,0.32) 0%, rgba(1,201,236,0.98) 100%, rgba(0,224,108,0.32) 100%) border-box;
          }
        }
      }
      .benefit-wrap-btn {
        @include text-16-fw-700;
        width: 100%;
        text-align: center;
        .benefit-btn {
          background-color: #EA2127;
          color: #ffffff;
          padding: 8px 16px;
          border: 1px solid #ffffff;
          border-radius: 4px;
          &:hover {
            background-color: #ffffff;
            color: #EA2127;
            border: 1px solid #EA2127;
          }
    }
      }
    }
  }