@import '../../../../common/styles/font-size';

// test-engine-introduction
.test-engine-introduction {
  padding: 152px 0px 76px 0px;
  .w-1440 {
    .introduction-text{
      margin: auto 0;
      padding-right: 40px;
      .introduction-title {
        position: relative;
        & > span {
          bottom: -88px;
          left: -65px;
          display: inline-block;
          z-index: 1;
          position: absolute;
          svg {
            font-size: 337px;
          }
        }
        & > h2 {
          position: relative;
          z-index: 2;
          @include text-42-fw-700;
          & > span {
            color: #EA2127;
          }
        }
      }
      & > p {
        @include text-16-fw-400;
      }
    }
    .introduction-img{
      & > img {
        width: 100%;
        border-radius: 8px;
      }
    }
  }
  
 
  
}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .test-engine-introduction {
    .w-1440 {
      .introduction-text {
        .introduction-title {
          & > span {
            display: none;
          }
        }
        & > h1 {
          @include text-32-fw-600;
          margin-bottom: 20px !important;
        }
        & > p {
          @include text-14-fw-400;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .test-engine-introduction {
    // margin: auto 20px;
  }
}

/*Mobile styles*/
@media screen and (min-width: 375px) and (max-width: 991px) {
  .test-engine-introduction {
    padding: 80px 0px 80px 0px;
    .w-1440 {
      margin: auto 16px;
      .introduction-text{
        .introduction-title {
          & > span {
            display: none;
          }
          & > h2 {
            @include text-26-fw-700;
            & > span {
              color: #EA2127;
            }
          }
        }
        & > p {
          @include text-16-fw-400;
        }
      }
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .test-engine-introduction {
    .w-1440 {
      .row {
        .introduction-text {
          margin-bottom: 20px;
        }
      }
    }
  }
}