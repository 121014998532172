@import '../../common/styles/font-size';

.release-notes-page {
  max-width: 1172px;
  margin: auto;
  padding: 0 16px;
  & > h1 {
    margin: 55px 0px;
    text-align: center;
    @include text-48-fw-400;
  }
  .release-notes-content {
   
    .notes-version {
      & > h3 {
        @include text-28-fw-600;
      }

      & > h5 {
        @include text-20-fw-600;
        color: #989ca2;
      }
      .notes-important {
        & > p {
          @include text-16-fw-700;
          margin-bottom: 20px;
        }
        & > ol {
          padding-left: 35px;
        }
        & > ol > li {
          @include text-16-fw-400;
        }
      }
    }
  }
}