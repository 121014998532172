@import '../../../common/styles/font-size';

.m-about-banner {
  margin: 0px auto 56px auto;
  max-width: 991px;
  padding: 0 16px;
  
  .banner-container {
    .banner-left {
      
    }

    .banner-right {
      background: #101828;
      color: #ffffff;
      padding: 10px 10px 50px 10px;
      border-bottom-left-radius: 60px;
      .banner-description {
        & > span {
          @include text-18-fw-600;
          color: #EA2127;
          font-family: var(--font-family);
        }
        & > h2 {
          @include text-32-fw-700;
        }
        & > p {
          @include text-14-fw-400;
          & > span {
            color: #EA2127;
          }
        }
      }
      .download-warp {
        text-align: center;
        .download-btn {
          margin: auto ;
          @include text-16-fw-700;
          color: #ffffff;
          border: 1px solid #EA2127;
          background: #EA2127;
          border-radius: 4px;
          padding: 8px 29px;
          &:hover {
            background: #ffffff;
            color: #EA2127;
            border: 1px solid #EA2127;
          }
        }
      }
      
    }

    // .banner-description {
    //   max-width: 28%;
    //   left: 60%;
    //   top: 50%;
    //   transform: translate(0, -50%);
    //   position: absolute;
    //   & > span {
    //     @include text-18-fw-600;
    //     color: #EA2127;
    //   }
    //   & > h2 {
    //     @include text-44-fw-700;
    //     color: #ffffff;
    //   }
    //   & > p {
    //     @include text-16-fw-400;
    //     color: #ffffff;
    //   }
    // }
  }
}


// /*Tablet styles*/
// @media screen and (min-width: 992px) and (max-width: 1199px) {
//   .m-about-banner {
//     .banner-container {
//       .banner-description {
//         left: 55%;
//         & > h2 {
//           @include text-44-fw-700;
//         }
//         & > p {
//           @include text-14-fw-400;
//         }
//       }
//     }
//   }
// }