@import '../../../../common/styles/font-size';


// home-blog
.m-home-blog {
  background: #F2F2F2;
  padding: 16px;
  .blog-tab {
    .tab-left {
      display: flex;
      width: fit-content;
      margin: auto;
      background: #FFFFFF;
      box-shadow: 0px 8px 16px rgba(238, 238, 238, 0.12);
      border-radius: 30px;
      .tab-item {
        @include text-14-fw-400;
        width: fit-content;
        text-align: center;
        cursor: pointer;
        padding: 6px 16px;
      }
      .tab-active{
        background: linear-gradient(270deg, rgba(1, 201, 236, 0.98) 52.43%, rgba(0, 224, 108, 0.32) 100%);
        border-radius: 60px;
        color: #FFFFFF;
      }
    }
  }
  
  .blog-tag {
    margin-left: auto;
    @include text-12-fw-500;
    width: fit-content;
    height: 22px;
    padding: 0px 12px;
    background: #EAECF0;
    border-radius: 20px;
  }
  .blog-item {
    max-width: 396px;
    margin: auto;
    & > a {
      text-decoration: none;
      color: #101828;
    }
    .blog-wrap {
      background: #FFFFFF;
      border-radius: 12px;
      padding: 20px;
      &:hover {
        box-shadow: 4px 4px 24px rgba(29, 36, 50, 0.1);
      }
      .blog-user {
        display: flex;
        gap: 10px;
        & > img {
          border-radius: 50%;
        }
        .blog-user-name {
          & > p {
            @include text-14-fw-700;
            margin: 0px;
          }
          & > span {
            @include text-12-fw-400;
          }
        }
      }
      .blog-content {
        width: 100%;
        min-height: 40px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        @include text-14-fw-700;
      }
    }
  }
  
}