@import '../../../../common/styles/font-size';

.download-test-management {
  padding: 20px 24px;
  background: #F2F2F2;
  border-radius: 20px;
  margin-bottom: 80px;
  .row {
    align-items: center;
    .test-engine-right {
      padding-left: 24px;
      & > h2 {
        @include text-42-fw-700;
      }
      & >  p {
        @include text-16-fw-400;
        & > span {
          color: #EA2127;
        }
      }
      .download-btn {
        @include text-16-fw-700;
        color: #ffffff;
        border: 1px solid #EA2127;
        background: #EA2127;
        border-radius: 4px;
        padding: 8px 29px;
        &:hover {
          background: #ffffff;
          color: #EA2127;
          border: 1px solid #EA2127;
        }
      }
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 991px) {
  .download-test-management {
    .row {
      .test-engine-right {
        padding-top: 20px;
        padding-left: 0px;
        & > h2 {
          @include text-26-fw-700;
        }
        & > p {
          margin-bottom: 30px !important;
        }
      }
    }
  }
}