@import '../../../common/styles/font-size';

.layout-nav {
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 12%);
  .container-fluid {
    margin: 16px 0px;
    & > a {
      margin-right: 49px;
    }

    .nav-item {
      & > a {
        color: #101828 !important;
        @include text-16-fw-700;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          width: 0;
          height: 5px;
          background: #EA2127;
          top: 40px;
          left: 50%;
          transform: translateX(-50%);
          transition: all .5s ease;
        }
        &:hover::after {
          // transform: scaleX(1);
          width: 100%;
        }
      }

      .has-submenu {
        display: none;
        position: absolute;
        padding-top: 30px;
        width: 218px;
        z-index: 1;
        box-shadow: 0 4px 2px -2px rgb(0 0 0 / 12%);
        .nav-submenu {
          background-color: #ffffff;
          padding: 20px 0px;
          border-radius: 8px;
          & > a {
            @include text-16-fw-700;
            float: none;
            color: #101828;
            padding: 8px 10px;
            text-decoration: none;
            display: block;
            text-align: left;
            border-left: 4px solid #ffffff;
            &:hover {
              background-color: #F2F2F2;
              border-left: 4px solid #EA2127 !important;
            }
          }
        }
      }

      .has-submenu-2 {
        display: none;
        position: absolute;
        padding-top: 30px;
        /*hidden submenu right*/
        // width: 888px;
        width: 385px;
        box-shadow: 0 4px 2px -2px rgb(0 0 0 / 12%);
        z-index: 1;
        .nav-submenu-2 {
          background-color: #ffffff;
          // padding: 30px;
          border-radius: 8px;
          display: flex;
          .nav-submenu-left {
            /*hidden submenu right*/
            // width: 40%;
            width: 100%;
            padding: 20px 30px 20px 30px;
            & > p {
              @include text-16-fw-500;
              color: #667085;
            }
            & > a {
              text-decoration: none;
              color: #101828;
              .submenu-item-product {
                display: flex;
                gap: 12px;
                align-items: center;
                border-left: 4px solid #ffffff;
                .product-icon {
                  padding-left: 8px;
                  max-width: 60px;
                  svg {
                    font-size: 36px;
                  }
                }
                .product-description {
                  & > p {
                    text-decoration: none;
                    @include text-16-fw-700;
                  }
                  
                  & > span {
                    @include text-13-fw-400;
                  }
                }
                &:hover {
                  background-color: #F2F2F2;
                  border-left: 4px solid #EA2127 !important;
                }
              }
            }
          }
          .nav-submenu-right {
            width: 60%;
            background: #F9FAFB;
            padding: 20px 30px 20px 20px;
            & > p {
              @include text-16-fw-500;
              color: #667085;
            }
            .submenu-solution-wrap {
              display: flex;
              gap: 20px;
              .solution-description {
                width: 50%;
                & > p {
                  @include text-16-fw-700;
                  &:hover{
                    color: #EA2127;
                    cursor: pointer;
                  }
                }
                & > span {
                  @include text-14-fw-400;
                }
              }
            }
          }
        }
      }

      &:hover {
        .has-submenu {
          display: block;
        }
        .has-submenu-2 {
          display: block;
        }
      }
    }

    .nav-left {
      .navbar-nav {
        gap: 16px;
      }
    }

    .nav-right {
      button {
        @include text-16-fw-700;
        &:hover{
          border: 1px solid transparent;
        }
      }
      & > a:nth-child(1) {
        button {
          color: #fff;
          background-color: #EA2127;
          border-color: #EA2127;
          &:hover {
            color: #EA2127;
            background-color: #fff;
            border-color: #EA2127;
          }
        }
      }
      & > a:nth-child(2) {
        button {
          color: #EA2127;
          border-color: #EA2127;
          &:hover {
            color: #fff;
            background-color: #EA2127;
            border-color: #EA2127;
          }
        }
      }
      .divider-vertical {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        font-size: 16px;
        padding-right: 10px;
        margin-right: 10px;
        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
          width: 1px;
          height: 14px;
          background: #667085;
        }
      }
      .current-user {
        display: inline-block;
        & > img {
          border-radius: 50%;
        }
        .has-user-info {
          display: none;
          position: absolute;
          padding-top: 30px;
          width: 120px;
          z-index: 1;
          box-shadow: 0 4px 2px -2px rgb(0 0 0 / 12%);
          .user-submenu {
            background-color: #ffffff;
            padding: 10px 0px;
            border-radius: 8px;
            & > a {
              @include text-16-fw-700;
              float: none;
              color: #101828;
              padding: 8px 10px;
              text-decoration: none;
              display: block;
              text-align: left;
              border-left: 4px solid #ffffff;
              &:hover {
                background-color: #F2F2F2;
                border-left: 4px solid #EA2127 !important;
              }
            }
          }
        }
        &:hover {
          .has-user-info {
            display: block;
          }
        }
      }
    }
  }
}

.layout-fixed {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 9000;
}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .layout-nav {
   .container-fluid {
      .nav-item {
        .has-submenu-2 {
          /*hidden submenu right*/
          // left: 60px;
        }
      }
   }
}
}

/** Mobile*/
@media screen and (min-width: 320px) and (max-width: 991px) {
  .layout-nav {
    .offcanvas-end {
      border: none;
      overflow: scroll;
    }
    .offcanvas-header {
      padding: 12px 16px;
    }
    .offcanvas-body {
      height: 100%;
      display : table;
      padding: 0px 16px;
      .m-nav-item {
        & > a {
          color: #101828 !important;
          @include text-16-fw-700;
          display: flex;
          justify-content: space-between;
        }
      }
  
      .m-nav-above {
        padding-bottom: 50px;
        .navbar-nav {
          gap: 16px;
        }
        .m-has-submenu {
          .m-header-submenu {
            align-items: baseline;
            @include text-16-fw-700;
            display: flex;
            & > p {
              flex: 1;
              text-align: center;
              padding: 12px;
            }
          }
          .m-nav-submenu {
            // background-clip: padding-box;
            border-radius: 8px;
            & > a {
              @include text-16-fw-700;
              float: none;
              color: #101828;
              padding: 8px 0px;
              text-decoration: none;
              display: block;
              text-align: left;
            }
          }
        }
      }
  
      .m-nav-below {
        display : table-row;
        vertical-align : bottom;
        height: 170px;
        text-align: center;
        a {
          display: block;
          padding: 0 16px;
          & > span {
            @include text-16-fw-700;
          }
        }
        & > a:nth-child(1) {
          width: 100%;
          button {
            width: 100%;
            color: #EA2127;
            border-color: #EA2127;
            &:hover {
              color: #fff;
              background-color: #EA2127;
              border-color: #EA2127;
            }
          }
        }
        & > a:nth-child(2) {
          width: 100%;
          button {
            width: 100%;
            color: #fff;
            background-color: #EA2127;
            border-color: #EA2127;
            &:hover {
              color: #EA2127;
              background-color: #fff;
              border-color: #EA2127;
            }
          }
        }
        & > button {
          &:active {
            border: none;
          }
        }
        .m-current-user {
          display: flex;
          justify-content: center;
          .m-has-user-info {
            & > a {
              & > img {
                border-radius: 50%;
              }
            }
            &:first-child {
              margin-right: 20px;
            }
            // .m-user-submenu {
            //   & > a {
            //     @include text-16-fw-700;
            //     float: none;
            //     color: #101828;
            //     padding: 8px 10px;
            //     text-decoration: none;
            //     display: block;
            //     text-align: left;
            //     border-left: 4px solid #ffffff;
            //   }
            // }
          }
        }
      }
    }
    
  }
}