@import '../../common/styles/font-size';

.insights-page {
  max-width: 1172px;
  margin: 80px auto 0px auto;
  padding: 0 16px;
  & > h1 {
    @include text-44-fw-700;
  }

  .insights-tab {
    display: flex;
    justify-content: space-between;
    .tab-left {
      display: flex;
      .tab-item {
        @include text-16-fw-700;
        color: #667085;
        width: 120px;
        margin: auto 0;
        text-align: center;
        cursor: pointer;
        padding-bottom: 16px;
        border-bottom: 2px solid #D0D5DD;
      }
      .tab-active{
        border-bottom: 2px solid red;
      }
    }
    .tab-right {
      flex: 1;
      border-bottom: 2px solid #D0D5DD;
      position: relative;
      .search-container {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        & > input {
          width: 100%;
          @include text-16-fw-400;
          outline: none;
          border-width: 0px;
          // border-color: #D0D5DD;
          &:focus {
            // border-color: #EA2127;
          }
          &::placeholder {
            @include text-16-fw-400;
          }
        }

        & > button {
          border: none;
          background: transparent;
          border-width: 0 0 2px;
          border-color: #D0D5DD;
        }
      
      }
      
    }
  }

  .more-tag {
    display: flex;
    flex-wrap: wrap;

    & > .insights-tag {
      @include text-14-fw-400;
      width: fit-content;
      padding: 4px 12px;
      background: #EAECF0;
      border-radius: 14px;
    }
    & > .insights-tag:not(:first-child) {
      margin-left: 10px;
    }
  }

  .insights-featured {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    .featured-header {
      flex: 0 0 100%;
      @include text-32-fw-600;
    }
    .featured-left {
      flex: 1;
      width: 966px;
      border: 1px solid #D0D5DD;
      border-radius: 20px;
      box-shadow: rgba(0, 0, 0, 0.161) 0px 1px 4px;
      img {
        border-radius: 20px 20px 0px 0px;
        width: 100%;
      }
      .featured-content {
        margin: 20px 20px 40px 20px;
        & > a {
          text-decoration: none;
          color: #101828;
          & > h5 {
            @include text-18-fw-600;
          }
          &:hover {
            color: #087cce;
          }
        }
        .featured-short-desc {
          color: #667085;
          @include text-16-fw-400;
        }
      }
     
    }
    .featured-right {
      max-width: 306px;
      & > a {
        text-decoration: none;
        color: #101828;
      }
      .featured-news {
        padding:  12px 12px 12px 20px;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        position: relative;
        .featured-vertical-line{
          border-radius: 2px;
          border-left: 4px solid #EA2127;
          height: 78%;
          position:absolute;
          left: 7px;
        }
        & > h5 {
          @include text-18-fw-600;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        &:hover {
          cursor: pointer;
          box-shadow: 4px 4px 24px rgba(29, 36, 50, 0.1);
        }
      }
     
    }
  }

  & > hr {
    margin-top: 0px;
    margin-bottom: 32px;
    height: 2px;
  }

  .insights-more {
    margin: 0px 0px 96px 0px;
    .more-header {
      flex: 0 0 100%;
      @include text-32-fw-600;
    }
    .more-wrap-item {
      display: flex;
      flex-wrap: wrap;
      column-gap: 24px;
      row-gap: 68px;
      .more-item {
        max-width: 354px;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.161) 0px 1px 4px;
        & > a {
          text-decoration: none;
          color: #101828;
          & > img {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            width: 100%;
          }
         .more-item-content {
          padding: 0px 10px;
          & > p {
            @include text-18-fw-600;
          }
          .more-short-desc {
            color: #667085;
            @include text-16-fw-400;
          }
         }
        }
      }
    }
    .insights-btn {
      padding-bottom: 116px;
      text-align: center;
      & > a {
        @include  text-16-fw-600;
        background: #EA2127;
        border-radius: 4px;
        border: none;
        padding: 14px 20px;
        color: #ffffff;
        text-decoration: none;
      }
    }
    .search_results_content {
      padding-bottom: 116px;
      & > p {
        @include text-16-fw-400;
      }
    }
  }
}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .insights-page {
    .insights-more {
      .more-wrap-item {
        .more-item {
          flex-basis: 31.3333%;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .insights-page {
    .insights-more {
      .more-wrap-item {
        .more-item {
          flex-basis: 31.3333%;
        }
      }
    }
  }
}

/*Mobile styles*/
@media screen and (min-width: 768px) and (max-width: 991px) {
  .insights-page {
    .insights-more {
      .more-wrap-item {
        justify-content: center;
      }
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .insights-page {
    & > h1 {
      @include text-36-fw-700;
    }
    .insights-tab {
      overflow: hidden;
     }   
    .insights-featured {
      justify-content: center;
      row-gap: 24px;
      .featured-left {
        max-width: 416px;
      }
      .featured-right {
        max-width: 416px;
      }
    }
    .insights-more {
      .more-wrap-item {
        justify-content: center;
      }
    }
  }
}


