@import '../../../../common/styles/font-size';


// download-end
.download-end {
  color: #ffffff;
  background-color: #101828;
  background: url('../../../../assets/images/download/image_download_end.png') center center no-repeat;
  background-size: cover;
  .download-container {
    max-width: 1172px;
    margin: auto !important;
    padding: 0 16px;
    margin: 0px;
    .banner-title{
      & > h1 {
        @include text-42-fw-700;
      }
      & > p {
        @include text-16-fw-600;
      }
      padding: 90px 0px;
    }
    .banner-btn{
      @include text-16-fw-700;
      color: #ffffff;
      border: 1px solid #ffffff;
      background: transparent;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      padding: 8px 12px;
      &:hover {
        color: #101828;
        background: #ffffff;
      }
    }
  }
}

// /*Tablet styles*/
// @media screen and (min-width: 992px) and (max-width: 1199px) {
//   .download-end {
//     .w-1440 {
//       margin: auto 16px;
//     }
//   }
// }

// @media screen and (min-width: 1200px) and (max-width: 1399px) {
//   .download-end {
//     // margin: auto 20px;
//   }
// }


/*mobile styles*/
@media screen and (min-width: 375px) and (max-width: 991px) {
  .download-end {
    .download-container {
      .banner-title {
        & > h1 {
          @include text-26-fw-700;
        }
        & > p {
          @include text-14-fw-400;
        }
      }
    }
  }
}