@import '../../../../common/styles/font-size';

// test-management-banner
.test-management-banner {
  color: #ffffff;
  background-color: #0f1727;
  .w-1440 {
    background: url('../../../../assets/images/aka-test-management/bg_banner.png') center center no-repeat;
    background-size: cover;
    .row {
      margin: 0px;
      .banner-title{
        & > h1 {
          @include text-42-fw-700;
        }
        & > p {
          @include text-16-fw-600;
        }
        padding: 90px 0px;
      }
      .banner-btn{
        @include text-16-fw-700;
        color: #ffffff;
        border: 1px solid #EA2127;
        background: #EA2127;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        padding: 8px 12px;
        &:hover {
          background: #ffffff;
          color: #EA2127;
          border: 1px solid #EA2127;
        }
      }
    }
  }
}

/*Tablet styles*/
// @media screen and (min-width: 992px) and (max-width: 1199px) {
//   .test-management-banner {
//     .w-1440 {
//       .banner-title {
//         & > h1 {
//           @include text-36-fw-700;
//         }
//         & > p {
//           @include text-14-fw-400;
//         }
//       }
//     }
//   }
// }

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .test-management-banner {
    // margin: auto 20px;
  }
}

@media screen and (min-width: 375px) and (max-width: 991px) {
  .test-management-banner {
    text-align: center;
    .w-1440 {
      .row {
        .banner-title {
          & > h1 {
            @include text-24-fw-700;
          }
          & > p {
            @include text-14-fw-400;
          }
        }
      }
    }
  }
}