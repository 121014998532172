.editor {
  box-shadow: 0 7px 14px rgb(162 166 209 / 40%);
  border-radius: 5px;
  border: 0;
  width: 510px;
  min-height: 140px;
  cursor: text;
  background-color: white;
  margin-left: 70px;
  margin-bottom: 30px;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}