@import '../../../../common/styles/font-size';

.ads-product {
  background: no-repeat url(../../../../assets/images/ads/image_bg_product.png);
  background-size: cover;
  width: 100%;
  .ads-product-container {
    max-width: 1172px;
    margin: 0px auto;
    padding: 80px 16px;
    .ads-product-introduction {
      display: flex;
      align-items: center;
      margin-bottom: 46px;
      .ads-product-left {
        width: 47%;
        color: #FCFCFD;
        position: relative;
        & > span {
          position: absolute;
          bottom: 0;
          left: -35%;
        }
        & > h3 {
          @include text-40-fw-700;
        }
        & > p {
          @include text-18-fw-300;
          margin-bottom: 37px;
          & > span {
            color: #e8b090;
          }
        }
        .ads-product-warp-btn {
          position: relative;
          & > a {
            & > button {
              @include text-16-fw-400;
            }
            &:nth-child(1) {
              button {
                color: #FCFCFD;
                background-color: #EA2127;
                border-color: #EA2127;
                &:hover {
                  color: #EA2127;
                  background-color: #FCFCFD;
                  border-color: #EA2127;
                }
                & > span {
                  vertical-align: bottom;
                }
              }
            }
            &:nth-child(2) {
              button {
                color: #FCFCFD;
                border-color: #FCFCFD;
                &:hover {
                  color: #FCFCFD;
                  background-color: #EA2127;
                  border-color: #EA2127;
                }
                & > span {
                  vertical-align: text-bottom;
                }
              }
            }
          }
        }
      }
      .ads-product-right {
        width: 53%;
      }
    }
    .ads-product-step {
      display: flex;
      justify-content: space-between;
      color: #FCFCFD;
      .product-step-detail {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #FCFCFD;
        & > p {
          @include text-16-fw-400;
          margin-right: 40px;
        }
        & > span {
          & > svg {
            width: 48px;
          }
        }
      }
    }
  }
  
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .ads-product {
    background: no-repeat url(../../../../assets/images/ads/mobile_bg_product.png);
    background-size: cover;
    .ads-product-container {
      padding: 32px 16px 16px 16px;
      .ads-product-introduction {
        flex-wrap: wrap;
        .ads-product-left, 
        .ads-product-right {
          width: 100%;
        }
        .ads-product-left {
          margin-bottom: 37px;
          & > span {
            display: none;
          }
          & > h3 {
            @include text-24-fw-700;
          }
          & > p {
            @include text-16-fw-400;
            
          }
          .ads-product-warp-btn {
            & > a {
              & > button {
                @include text-14-fw-400;
              }
            }
          }
        }
      }
      .ads-product-step {
        flex-wrap: wrap;
        .product-step-detail {
          margin-bottom: 16px;
          width: 100%;
          justify-content: space-between;
          & > span {
            & > svg {
              width: 24px;
            }
          }
        }
      }
    }
  }
}