@import '../../../../common/styles/font-size';

.profile-person {
  background: #FCFCFD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 28px 32px;
  .person-header {
    display: flex;
    justify-content: space-between;
    & > h3 {
      @include text-24-fw-700;
    }
    & > button {
      background-color: #EA2127;
      color: #fff;
      &:hover {
        color: #EA2127;
        background-color: #fff;
        border-color: #EA2127;
      }
    }
  }
  .person-form {
    .person-form-warp {
      display: flex;
      width: 100%;
      gap: 87px;
      .person-form-item {
        width: 50%;
        display: flex;
        align-items: center;
        & > label {
          @include text-16-fw-700;
          width: 30%;
        }
        & > input {
          border: 1px solid #D0D5DD;
          border-radius: 4px;
          width: 70%;
          @include text-16-fw-400;
          outline: none;
          padding: 12px;
          &:disabled {
            @include text-16-fw-700;
            color: #344054;
            background: #FCFCFD;
            border: none;
          }
          &::placeholder {
            @include text-16-fw-400;
          }
        }
      }
      .person-form-item-disable {
        & > input {
          background: #d0d5dd !important;
          @include text-16-fw-400;
        }
      }
    }
    .person-wrap-btn {
      text-align: end;
      .btn-cancel {
        background: transparent;
        border: none;
        @include text-16-fw-700;
        color: #667085;
        vertical-align: middle;
      }
      .person-btn {
        background-color: #EA2127;
        color: #fff;
        padding: 8px 12px;
        &:hover {
          color: #EA2127;
          background-color: #fff;
          border-color: #EA2127;
        }
      }
    }
  }
  .person-space-46 {
    height: 46px;
  }
}



/*Mobile styles*/
@media screen and (min-width: 320px) and (max-width: 767px) {
  .profile-person {
    .person-form {
      .person-form-warp {
        flex-wrap: wrap;
        gap: 40px;
        .person-form-item {
          width: 100%;
          display: unset;
          & > input {
            width: 100%;
          }
        }
      }
    }
  }
}