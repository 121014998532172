@import '../../../../common/styles/font-size';

.aka-studio-environment {
  .w-1440 {
    .environment-header {
      display: flex;
      & > h2 {
        @include text-42-fw-700;
      }
      & > p {
        @include text-16-fw-400;
      }
    }
    .environment-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .environment-left {
        .environment-item {
          cursor: pointer;
          box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
          border-radius: 12px;
          display: flex;
          padding: 12px 20px;
          align-items: center;
          & > p {
            @include text-24-fw-700;
          }
          .environment-item-number{
            @include text-28-fw-700;
            width: 46px;
            height: 46px;
            color: #EA2127;
            background-color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            margin-right: 20px;
          }
        }
        .item-active {
          background: #111121;
          color: #FFFFFF;
        }
      }
    }
   
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .aka-studio-environment {
    .w-1440 {
      .environment-header {
        flex-wrap: wrap;
      }

    }
  }
}

@media screen and (min-width: 375px) and (max-width: 991px) {
  .aka-studio-environment {
    .w-1440 {
      .environment-header {
        flex-wrap: wrap;
        justify-content: center;
        & >h2 {
          @include text-24-fw-700;
          text-align: center;
        }
        & > p {
          text-align: center;
        }
      }

      .environment-list {
        flex-direction: column-reverse;
        .environment-left {
          .environment-item {
            & > p {
              @include text-16-fw-700;
            }
          }
        }
        .environment-right {
          margin-bottom: 60px;
        }
      }
      
    }
  }
}