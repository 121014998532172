@import '../../../../common/styles/font-size';

// home-feedback
.home-feedback{
  background-image: url("../../../../assets/images/image_bg_home_feedback.png");
  background-size: cover; 
  padding: 80px 0px;
  .w-1440 {
    display: flex;
    gap: 12px;
    .feedback-left{
      width: 25%;
      margin: auto;
      & > h2 {
        @include text-42-fw-700;
        bottom: 16px;
        & > span {
          color: #EA2127;
        }
      }
    }
    .feedback-center{
      .slick-list {
        height: 745px !important;
        .slick-center {
          .feedback-item {
            background-color: #EA2127;
            color: white;
            margin-left: 86px;
            width: unset !important;
            .feedback-content { 
              .feedback-btn {
                color: white;
              }
            }
          }
        }
      }
     
  
      .feedback-item{
        background: #ffffff;
        display: flex !important;
        border-radius: 8px;
        padding: 16px;
        gap: 36px;
        .feedback-logo-customer{
          & > img {
            max-width: 164px;
            border-radius: 4px;
          }
        }
        .feedback-content {
          // .warp-article {
          //   .slick-list {
              
          //   }
          //   .slick-slide {
          //     transform: scale(0.7, 0.7);
          //     transition: transform 0.3s;
          //     &.slick-center {
          //       color:red
          //     }
          //   }
          // }
          
          .feedback-content-name {
            @include text-16-fw-600;
          }
          .feedback-content-header {
            @include text-22-fw-600;
            margin-bottom: 4px;
          }
          .feedback-content-comment {
            @include text-14-fw-400;
          }
          .feedback-btn{
            margin: 0;
            color: black;
            text-decoration: none;
            @include text-16-fw-700;
          }
          
        }
      }
     
    }
  
    .feedback-right {
      margin: auto;
      .feedback-arrow{
        background: #101828;
        border-radius: 8px;
        padding: 8px;
        cursor: pointer;
        color: #ffffff;
        &:hover {
          background: #EA2127;
        }
      }
    }
  }

}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .home-feedback {
    .w-1440 {
      flex-wrap: wrap;
      .feedback-left {
        width: 100%;
        text-align: center;
        & > h1 {
          @include text-36-fw-700;
        }
      }
      .feedback-center {
        flex: 1;
        .slick-list {
          // height: 1025px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .home-feedback {
    .w-1440 {
      .feedback-left {
        max-width: 240px;
      }
      .feedback-center {
        width: 80% !important;
      }
    }
  }
}
