// $fontFamily: var(--bs-font-sans-serif);
$fontFamily: var(--font-family);

@function calculateRem($rem) {
  @return calc(#{$rem}/#{var(--rem-px-ratio)});
}

.w-1440 {
  max-width: 1150px;
  margin: auto;
}
.row {
  margin: 0px;
  & > * {
    padding: 0px;
  }
}
/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1374px) {
  .w-1440 {
    margin: auto 70px;
  }
}

/*End Tablet styles*/

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*Margin Bottom style*/

$divider-values: (
  4: 4px,
  6: 6px,
  8: 8px,
  12: 12px,
  16: 16px,
  20: 20px,
  22: 22px,
  24: 24px,
  26: 26px,
  28: 28px,
  30: 30px,
  32: 32px,
  35: 35px,
  40: 40px,
  43: 43px,
  48: 48px,
  52: 52px,
  60: 60px,
  64: 64px,
  68: 68px,
  70: 70px,
  76: 76px,
  80: 80px,
  95: 95px,
  116: 116px,
  120: 120px,
  140: 140px,
  145: 145px,
  148: 148px,
  163: 163px,
  // Other values if you want to add
);
@each $value, $margin in $divider-values {
  .divider-#{$value} {
    margin-bottom: $margin !important;
  }
}
