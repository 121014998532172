@import '../../../../common/styles/font-size';


// home-blog
.home-blog {
  background: #F2F2F2;
  .w-1440 {
    display: flex;
    flex-wrap: wrap;
    column-gap: 54px;
    // justify-content: center;
    align-items: center;
    padding-bottom: 96px;
    .blog-tag {
      margin-left: auto;
      @include text-12-fw-500;
      width: fit-content;
      height: 22px;
      padding: 0px 12px;
      background: #EAECF0;
      border-radius: 20px;
    }
    .blog-item {
      width: 351px;
      flex: 1;
      & > a {
        text-decoration: none;
        color: #101828;
      }
      .blog-wrap {
        background: #FFFFFF;
        border-radius: 12px;
        padding: 20px;
        &:hover {
          box-shadow: 4px 4px 24px rgba(29, 36, 50, 0.1);
        }
        .blog-user {
          display: flex;
          gap: 10px;
          & > img {
            border-radius: 50%;
          }
          .blog-user-name {
            & > p {
              @include text-14-fw-700;
              margin: 0px;
            }
            & > span {
              @include text-12-fw-400;
            }
          }
        }
        .blog-content {
          width: 100%;
          min-height: 40px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          @include text-14-fw-700;
        }
      }
    }
  }
  
  
  // .blog-wrap-btn {
  //   @include text-16-fw-700;
  //   width: 100%;
  //   text-align: center;
  //   .blog-btn {
  //     background-color: #EA2127;
  //     color: #ffffff;
  //     padding: 8px 16px;
  //     border: 1px solid #ffffff;
  //     border-radius: 4px;
  //     &:hover {
  //       background-color: #ffffff;
  //       color: #EA2127;
  //       border: 1px solid #EA2127;
  //     }
  //   }
  // }
  
}

/*Tablet styles*/
@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .home-blog {
    .w-1440 {
      column-gap: 24px;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .home-blog {
    .w-1440 {
      column-gap: 24px;
    }
  }
}