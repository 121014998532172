@import '../../../../common/styles/font-size';

.test-engine-feature {
  margin: 0px 0px 90px 0px;
  .w-1440 {
    & > h2 {
      text-align: center;
      @include text-42-fw-700;
      & > span {
        color: #EA2127;
      }
    }
    .feature-container {
      background: url('../../../../assets//images/aka-studio/bg_features.png') center center no-repeat;
      background-size: 100% 100%;
      justify-content: center;
      position: relative;
      .feature-center {
        background: url('../../../../assets//images/aka-studio/bg_features.png') center center no-repeat;
        background-size: 100% 100%;
        justify-content: center;
        margin: auto;
        gap: 48px;
        .feature-item {
          padding: 20px 12px;
          background: #FFFFFF;
          border: 1px solid #EAECF0;
          border-radius: 12px;
          height: 248px;
          box-shadow: 0px 4px 48px -12px rgba(16, 24, 40, 0.15);
          &.feature-item-right{
            text-align: right;
          }
          & > h3 {
            @include text-20-fw-700;
          }
          & > p {
            @include text-14-fw-400;
          }
          .feature-icon-right {
            & > svg {
                display: block;
                margin: 0 0 0 auto;
            }
          }
        }
        
      }
      .feature-img {
        margin: auto;
        max-width: 234px;
        position: absolute; 
        left: 0; 
        right: 0;
        bottom: 27%;
        margin-left: auto; 
        margin-right: auto;
      }
    }
    
  }
}