@import "../../../../common/styles/font-size";

// home-feedback
.m-home-feedback {
  background-image: url("../../../../assets/images/image_bg_home_feedback.png");
  background-size: cover;
  padding: 60px 0px;
  overflow: hidden;
  .feedback-left {
    & > h2 {
      @include text-26-fw-700;
      text-align: center;
      bottom: 16px;
      & > span {
        color: #ea2127;
      }
    }
  }
  .feedback-slides {
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            & > div {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .slick-dots {
        & > li {
          margin-right: 8px;
          & > button {
            width: 20px;
            height: 4px;
            background-color: #d9d9d9;
            border-radius: 4px;
            padding: 0;
            &::before {
              display: none;
            }
          }
        }
        .slick-active {
          & > button {
            background: #ea2127;
          }
        }
      }
    }

    .feedback-item {
      background: #ffffff;
      border-radius: 8px;
      padding: 16px;
      gap: 36px;
      max-width: 343px;
      min-height: 409px;
      .feedback-logo-customer {
        & > img {
          width: 100%;
        }
      }
      .feedback-content {
        min-height: 228px;
        .feedback-content-name {
          @include text-16-fw-600;
        }
        .feedback-content-header {
          @include text-26-fw-700;
          margin-bottom: 4px;
        }
        .feedback-content-comment {
          @include text-16-fw-400;
        }
        & > label {
          @include text-16-fw-700;
        }
      }
    }
  }
}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
