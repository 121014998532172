@import "../../../../common/styles/font-size";

.pricing-started {
  background: url("../../../../assets/images/image_product.png") center center
    no-repeat;
  background-size: cover;
  padding: 60px 0px;
  & > h1 {
    color: #ffffff;
    text-align: center;
    @include text-42-fw-700;
  }
  & > p {
    color: #ffffff;
    text-align: center;
    @include text-20-fw-500;
  }

  .pricing-tab {
    .tab-left {
      width: fit-content;
      margin: auto;
      background: #ffffff;
      box-shadow: 0px 8px 16px rgba(238, 238, 238, 0.12);
      border-radius: 30px;
      .tab-item {
        display: table-cell;
        vertical-align: middle;
        @include text-14-fw-400;
        text-align: center;
        cursor: pointer;
        padding: 8px 18px;
      }
      .tab-active {
        background: linear-gradient(
          90deg,
          rgba(1, 201, 236, 0.98) 57.5%,
          #00e06c 117.45%
        );
        border-radius: 60px;
        color: #ffffff;
      }
    }
  }
  .warp-product {
    display: flex;
    justify-content: center;
    .grid-box {
      display: grid;
      grid-column-gap: 20px;
      grid-template-columns: repeat(3, 1fr);

      .cell {
        background: #ffffff;
        max-width: 371px;
        padding: 0px 30px;
        .product-header {
          text-align: center;
          & > span {
            display: inline-block;
            background: #f2f2f2;
            padding: 20px;
            border-radius: 8px;
            svg {
              font-size: 45px;
            }
          }
          .product-tag {
            margin: auto;
            @include text-16-fw-600;
            width: fit-content;
            padding: 4px 12px;
            border-radius: 32px;
            background: #f2f2f2;
          }
          & > h2 {
            @include text-28-fw-700;
          }
          & > p {
            @include text-16-fw-600;
            & > span {
              font-family: var(--font-archia) !important;
              @include text-28-fw-700;
              color: #ec5357;
            }
            .color-quick-silver {
              @include text-16-fw-600;
              color: #a5a5a5;
            }
          }
        }
        .product-content {
          margin-top: 40px;
          .product-benefit {
            @include text-14-fw-400;
            display: flex;
            gap: 16px;
            // align-items: center;
            & > p > span {
              color: #ec5357;
              @include text-20-fw-700;
            }
          }
        }
        .wrap-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          & > a {
            width: 93%;
            .product-btn {
              width: 100%;
              @include text-16-fw-700;
              background-color: #ea2127;
              color: #ffffff;
              padding: 8px 12px;
              border: 1px solid #ea2127;
              border-radius: 4px;
              &:hover {
                background: #ffffff;
                color: #ea2127;
                border: 1px solid #ea2127;
              }
            }
          }
        }
      }
      .cell-border-top {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        padding-top: 35px;
      }
      .cell-border-bottom {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        padding-bottom: 70px;
      }
    }
    .grid-box-studio {
      display: grid;
      grid-column-gap: 20px;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .pricing-started {
    & > h3 {
      @include text-36-fw-700;
      margin-bottom: unset !important;
    }
    & > p {
      color: #ffffff;
      text-align: center;
      @include text-12-fw-400;
    }
    .warp-product {
      .grid-box {
        grid-column-gap: 12px;
        .cell {
          padding: 0 10px;
          max-width: 307px;
          .product {
            .product-header {
              & > h3 {
                @include text-24-fw-700;
              }
              & > p {
                @include text-14-fw-700;
              }
            }
            .product-content {
              .product-benefit {
                @include text-14-fw-400;
              }
            }
          }
        }
        .cell-border-top {
          padding-top: 35px;
        }
        .cell-border-bottom {
          padding-bottom: 70px;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .pricing-started {
    // margin: auto 20px;
  }
}
