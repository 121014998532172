@import "../../../../common/styles/font-size";
// home-product
.m-home-product {
  background: url("../../../../assets/images/mobile_image_product.png") center
    center no-repeat;
  background-size: cover;
  padding: 60px 16px;
  .w-375 {
    & > h2 {
      color: #ffffff;
      text-align: center;
      @include text-26-fw-700;
    }
    & > p {
      color: #ffffff;
      text-align: center;
      @include text-18-fw-400;
    }
    .product {
      background-color: #ffffff;
      border-radius: 12px;
      padding: 20px 16px;
      .product-header {
        text-align: center;
        .product-tag {
          margin: auto;
          @include text-16-fw-600;
          width: fit-content;
          padding: 4px 12px;
          border-radius: 32px;
        }
        & > h3 {
          @include text-28-fw-700;
        }
        & > p {
          @include text-16-fw-600;
        }
      }
      .product-content {
        margin-top: 40px;
        .product-benefit {
          @include text-16-fw-400;
          display: flex;
          gap: 16px;
        }
        .wrap-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          .product-btn {
            @include text-16-fw-700;
            background-color: #ea2127;
            color: #ffffff;
            padding: 8px 12px;
            border: 1px solid #ea2127;
            border-radius: 4px;
            &:hover {
              background: #ffffff;
              color: #ea2127;
              border: 1px solid #ea2127;
            }
          }
        }
      }
    }
  }
}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .home-product {
    // to do something
  }
}
