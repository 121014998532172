@import "../../../../common/styles/font-size";
.m-home-why {
  // background-color: #F2F4F7;
  padding: 80px 0px;
  margin: auto 16px;
  & > h3 {
    text-align: center;
    @include text-26-fw-700;
  }
  .why-highlights {
    .why-image {
      margin: auto;
    }
    .why-left {
      margin: auto 0;
      .why-ordinal {
        @include text-28-fw-700;
        width: 46px;
        height: 46px;
        color: #ffffff;
        background: #101828;
        box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
          0px 4px 12px -2px rgba(16, 24, 40, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
      }
      & > h3 {
        @include text-18-fw-600;
      }
      & > p {
        @include text-16-fw-400;
      }
    }
    .why-right {
      // width: 594px;
      background: url("../../../../assets/images/image_global_why_bg.png")
        center center no-repeat;
      background-size: cover;
      svg {
        margin: auto;
        display: block;
      }
    }
  }
}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .home-why {
    .w-1440 {
      & > h3 {
        @include text-36-fw-700;
      }
      .why-highlights {
        .why-left {
          & > h3 {
            @include text-36-fw-700;
          }
          & > p {
            @include text-14-fw-400;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
}
