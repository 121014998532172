@import '../../../../common/styles/font-size';

.about-banner {
  // margin: 0px auto 56px auto;
  // max-width: 1172px;
  // padding: 0 16px;
  
  .banner-container {
    display: flex;
    flex-direction: column;
    position: relative;
    // .banner-left {
    //   width: 54%;
    //   position: relative;
    //   .download-btn {
    //     position: absolute;
    //     top: 30%;
    //     right: 0;
    //     @include text-16-fw-700;
    //     color: #ffffff;
    //     border: 1px solid #EA2127;
    //     background: #EA2127;
    //     border-radius: 4px;
    //     padding: 8px 29px;
    //     &:hover {
    //       background: #ffffff;
    //       color: #EA2127;
    //       border: 1px solid #EA2127;
    //     }
    //   }
    // }

    // .banner-right {
    //   width: 46%;
    //   color: #ffffff;
    //   .banner-description {
    //     & > span {
    //       @include text-18-fw-600;
    //       color: #EA2127;
    //     }
    //     & > h2 {
    //       @include text-44-fw-700;
    //     }
    //     & > p {
    //       @include text-16-fw-400;
    //     }
    //   }
    // }

    .banner-description {
      max-width: 28%;
      left: 60%;
      top: 50%;
      transform: translate(0, -50%);
      position: absolute;
      & > h1 {
        @include text-18-fw-600;
        color: #EA2127;
      }
      & > h2 {
        @include text-44-fw-700;
        color: #ffffff;
      }
      & > p {
        @include text-16-fw-400;
        color: #ffffff;
        & > span {
          color: #EA2127;
        }
      }
    }
  }

  .download-warp {
    text-align: center;
    .download-btn {
      margin: auto ;
      @include text-16-fw-700;
      color: #ffffff;
      border: 1px solid #EA2127;
      background: #EA2127;
      border-radius: 4px;
      padding: 8px 29px;
      &:hover {
        background: #ffffff;
        color: #EA2127;
        border: 1px solid #EA2127;
      }
    }
  }
}


/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .about-banner {
    .banner-container {
      .banner-description {
        max-width: 33%;
        left: 55%;
        & > h2 {
          @include text-44-fw-700;
        }
        & > p {
          @include text-14-fw-400;
        }
      }
    }
  }
}