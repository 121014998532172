@import '../../../../common/styles/font-size';

.about-contact {
  margin: 0 auto;
  padding: 80px 0px;
  .contact-required {
    color: #EA2127;
  }
  .contact-header {
    text-align: center;
    & > h2 {
      @include text-44-fw-700;
    }
    &> p {
      @include text-16-fw-600;
    }
  }
  .contact-wrap {
    max-width: 1140px;
    display: flex;
    gap: 24px;
    margin: 0 auto;
    .contact-left {
      width: 38.4%;
      position: relative;
      background: url('../../../../assets/images/about/image_switchboard\ operator.png') center center no-repeat;
      background-size: cover;
      background-position: center top 7%;
      background-blend-mode: multiply, normal;
      color: #FFFFFF;
      padding: 40px;
      .contact-icon-social {
        position: absolute;
        bottom: 45px;
        left: 40px;
      }
    }
    .contact-right {
      width: 752px;
      padding: 40px;
      .contact-form-field {
        label {
          @include text-12-fw-400;
        }
        input {
          width: 100%;
          @include text-16-fw-400;
          outline: none;
          border-width: 0 0 2px;
          border-color: #D0D5DD;
          padding: 8px 0px;
          &:focus {
            border-color: #EA2127;
          }
          &::placeholder {
            @include text-16-fw-400;
          }
        }

        textarea {
          resize: none;
          @include text-16-fw-400;
          width: 100%;
          border-width:  0 0 2px;
          border-color: #D0D5DD;
          outline: none;
          &:focus {
            border-color: #EA2127;
          }
          &::placeholder {
            @include text-16-fw-400;
          }
        }
      }
      
      .contact-wrap-field {
        width: 100%;
        display: flex;
        gap: 41px;
        .contact-row {
          width: 50%;
        }
      }

      .contact-captcha {
        div {
          height: unset !important;
          border-radius: 20px;
        }
      }

      .contact-btn {
        @include text-16-fw-700;
        background-color: #EA2127;
        color: #ffffff;
        padding: 8px 12px;
        border: none;
        border-radius: 4px;
      }
    }
  }
}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1354px) {
  .about-contact {
    padding: 80px 20px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-contact {

  }
}
/*Mobile styles*/
@media screen and (min-width: 375px) and (max-width: 991px) {
  .about-contact {
    padding: 0px 16px 80px 16px;
    .contact-header {
      text-align: center;
      & > h2 {
        @include text-28-fw-700;
      }
      &> p {
        @include text-16-fw-400;
      }
    }
    .contact-wrap {
      flex-direction: column;
      .contact-left {
        width: 100%;
        min-height: 500px;
        padding: 20px;
        .divider-68 {
          margin-bottom: 40px !important;
        }
        .contact-icon-social {
          left: 20px;
        }
      }
      .contact-right {
        width: 100%;
        padding: 0;
        .contact-wrap-field {
          flex-wrap: wrap;
          .contact-row {
            width: 100%;
          }
        }
      }
    }
  }
}