@import '../../../../common/styles/font-size';

.about-mission {
  background: #F9FAFB;
  .mission-container {
    margin: 0px auto auto auto;
    max-width: 1172px;
    padding: 0 16px 200px 16px;
    align-items: center;
    position: relative;
    .test-engine-left {
      padding-right: 10px;
      & > h2 {
        @include text-22-fw-600;
        color: #EA2127;
      }
      & > h3 {
        @include text-42-fw-700;
      }
      & >  p {
        @include text-16-fw-400;
      }
    }
    .about-mission-center {
      max-width: 394px;
      position: absolute;
      bottom: -20%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}


@media screen and (min-width: 992px) and (max-width: 1060px) {
  .about-mission {
    .mission-container {
      padding: 0 16px 240px 16px;
      .about-mission-center {
      
      }
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 991px) {
  .about-mission {
    .mission-container {
      flex-direction: column-reverse;
      padding: 0 16px 40px 16px;
      .test-engine-left {
        padding-top: 20px;
        & > h3 {
          @include text-26-fw-700;
        }
        & > p {
          margin-bottom: 30px !important;
        }
      }
      .about-mission-center {
        display: none;
      }
    }
  }
}