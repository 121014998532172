@import '../../common/styles/font-size';

.events-webinars-page {
  max-width: 1172px;
  margin: 80px auto 134px auto;
  padding: 0 16px;
  & > h1 {
    @include text-44-fw-700;
  }

  .events-tab {
    display: flex;
    justify-content: space-between;
    .tab-left {
      display: flex;
      .tab-item {
        @include text-16-fw-700;
        color: #667085;
        width: 120px;
        margin: auto 0;
        text-align: center;
        cursor: pointer;
        padding-bottom: 16px;
        border-bottom: 2px solid #D0D5DD;
      }
      .tab-active{
        border-bottom: 2px solid red;
      }
    }
    .tab-right {
      flex: 1;
      border-bottom: 2px solid #D0D5DD;
      position: relative;
      .search-container {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        & > input {
          width: 100%;
          @include text-16-fw-400;
          outline: none;
          border-width: 0px;
          // border-color: #D0D5DD;
          &:focus {
            // border-color: #EA2127;
          }
          &::placeholder {
            @include text-16-fw-400;
          }
        }

        & > button {
          border: none;
          background: transparent;
          border-width: 0 0 2px;
          border-color: #D0D5DD;
        }
      
      }
    }
  }

  .events-tag {
    @include text-14-fw-400;
    width: fit-content;
    padding: 4px 12px;
    background: #EAECF0;
    border-radius: 14px;
  }

  & > hr {
    margin-top: 0px;
    margin-bottom: 32px;
    height: 2px;
  }

}

/*Mobile styles*/

@media screen and (min-width: 375px) and (max-width: 767px) {
  .events-webinars-page {
    & > h1 {
      @include text-36-fw-700;
    }
    .events-tab {
      overflow: hidden;
     }
  }
}