@import '../../../../common/styles/font-size';

.event-article-page {
  max-width: 1172px;
  margin: 80px auto 80px auto;
  padding: 0 16px;
  & > h1 {
    @include text-44-fw-700;
  }
  .event-tag {
    width: fit-content;
    padding: 4px 10px;
    background: #ffffff;
    border-radius: 14px;
  }

  .event-article-header {
    display: flex;
    background: url('../../../../assets/images/image_graywood_grain.png') center center no-repeat, #F2F2F2;
    background-size: cover;
    border-radius: 20px;
    gap: 24px;
    padding: 40px;
    .event-article-left{
      flex: 1;
      .event-article-tag-wrap {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        @include text-14-fw-400;
      }

      & > h1 {
        @include text-36-fw-700;
      }
      & > p {
        @include text-16-fw-700;
        color: #667085;
      }
    }
    .event-article-right {
      max-width: 506px;
      & > img {
        width: 100%;
        border-radius: 20px;
      }
    }
  }
  .event-article-content {
    display: flex;
    gap: 24px;
    .event-article-content-left {
      max-width: 856px;
      @include text-16-fw-400;
      text-align: justify;
    }
    .event-article-content-right {
      position: sticky;
      top: 100px;
      width: 415px;
      min-width: 335px;
      padding: 8px 24px;
      height: fit-content;
      background: #F2F2F2;
      border-radius: 8px;
      .event-article-toc {
        & > ul {
          padding: 0px;
          li {
            list-style-type: none;
            & > a {
              @include text-16-fw-400;
              text-decoration: none;
              color: #101828;
            }
          }
        }
      }
     
    }
  }
  .event-article-social-network {
    & > p {
      @include text-16-fw-400;
    }
    .wrap-icons {
      background-color: #F2F2F2;
      border-radius: 20px;
      padding: 20px;
      width: fit-content;
    }
  }
  .event-article-footer{
    max-width: 856px;
    margin: auto;
    position: relative;
    background: url('../../../../assets/images/image_graywood_grain_event.png') center center no-repeat;
    background-size: cover;
    border-radius: 20px;
    padding: 40px;
    & > h3 {
      @include  text-36-fw-700;
      color: white;
      text-align: center;
    }
    .event-wrap-email {
      background-color: #ffffff;
      color: #101828;
      margin: auto;
      padding: 40px;
      border-radius: 20px;
      & > label {
        @include text-12-fw-400;
      }
      & > input {
        width: 100%;
        @include text-16-fw-400;
        outline: none;
        padding: 8px 0px;
        border-width: 0 0 2px;
        border-color: #D0D5DD;
        &:focus {
          border-color: #EA2127;
        }
        &::placeholder {
          @include text-16-fw-400;
        }
      }
      .event-article-btn {
        text-align: center;
        & > button {
          @include  text-16-fw-600;
          background: #EA2127;
          border-radius: 4px;
          border: none;
          padding: 14px 24px;
          color: #ffffff;
          &:disabled {
            background-color: #999999;
          }
        }
      }
    }
  }
}

/*Mobile styles*/
@media screen and (min-width: 375px) and (max-width: 991px) {
  .event-article-page {
    & > h1 {
      @include text-36-fw-700;
    }
    .event-article-header {
      flex-direction: column-reverse;
      padding: 16px;
      gap: 0px;
      .event-article-left{
        .divider-48 {
          margin-bottom: 20px !important;
        }
        & > h1 {
          @include text-24-fw-700;
        }
        & > p {
          @include text-14-fw-400;
        }
      }
      .event-article-right {
        width: unset;
        margin: auto;
        margin-bottom: 24px;
      }
  
    }
    .event-article-content {
      flex-direction: column-reverse;
      gap: 0px;
      .event-article-content-right {
        position: unset;
        width: unset;
        max-width: 415px;
        margin-bottom: 24px;
      }
    }

    .event-article-footer {
      padding: 20px;
      & > h3 {
        @include  text-28-fw-700;
      }
      .event-wrap-email {
        padding: 20px;
      }
    }
  }
}