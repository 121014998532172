@import '../../../common/styles/font-size';

.webinars-page {
  .webinars-tag {
    width: fit-content;
    padding: 4px 10px;
    background: #ffffff;
    border-radius: 14px;
  }

  .webinars-article-header {
    background: url('../../../assets/images/image_graywood_grain.png') center center no-repeat, #F2F2F2;
    background-size: cover;
    border-radius: 20px;
    padding: 40px;
    .webinars-left{
      padding-right: 24px;
      .webinars-btn {
        & > a, button {
          @include  text-16-fw-600;
          background: #EA2127;
          border-radius: 4px;
          border: none;
          padding: 14px 20px;
          color: #ffffff;
          text-decoration: none;
        }
      }

      .webinars-tag-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline;
        @include text-14-fw-400;
      }

      & > h1 {
        @include text-32-fw-600;
      }
      & > p {
        @include text-16-fw-700;
        color: #667085;
      }
    }
    .webinars-article-right {

      & > img {
        width: 100%;
      }
      .image-container {
        position: relative;
        .introduction-icon{
          width: 42px;
          height: 42px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        & > img {
          border-radius: 20px;
        }
      }
      
    }
  }
  & > hr {
    margin-top: 0px;
    margin-bottom: 32px;
    height: 2px;
  }

  .webinars-more {
    margin: 0px 0px 96px 0px;
    & > h2 {
      flex: 0 0 100%;
      @include text-32-fw-600;
    }
    .more-wrap-item {
      display: flex;
      flex-wrap: wrap;
      column-gap: 24px;
      row-gap: 68px;
      .more-item {
        max-width: 354px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        .more-img {
          position: relative;
          & > img {
            border-radius: 20px;
            width: 100%;
          }
          .more-icon{
            width: 42px;
            height: 42px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        & > h3 {
          @include text-18-fw-600;
        }
        & > p {
          color: #667085;
          @include text-16-fw-400;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }

      }
    }
    .search_results_content {
      padding-bottom: 116px;
      & > p {
        @include text-16-fw-400;
      }
    }
  }
}

/*-----------------------------------------------------*/
/*Tablet styles*/

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .webinars-page {
    .webinars-more {
      .more-wrap-item {
        .more-item {
          flex-basis: 31.3333%;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .webinars-page {
    .webinars-more {
      .more-wrap-item {
        .more-item {
          flex-basis: 31.3333%;
        }
      }
    }
  }
}

/*Mobile styles*/
@media screen and (min-width: 375px) and (max-width: 991px) {
  .webinars-page {
    .webinars-article-header {
      padding: 16px;
      .row {
        flex-direction: column-reverse;
        .webinars-left {
          padding-right: 0px;
          .divider-48 {
            margin-bottom: 20px !important;
          }
          & > h1 {
            @include text-24-fw-700;
          }
          & > p {
            @include text-14-fw-400;
          }
        }
        .webinars-article-right {
          width: unset;
          margin: auto;
          margin-bottom: 20px;
          }  
        }
    }

    .webinars-more {
      .more-wrap-item {
        justify-content: center;
      }
    }
  }
}