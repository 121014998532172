@import '../../../../common/styles/font-size';

.about-boss {
  // padding: 20px 24px;
  // background: #F2F2F2;
  // border-radius: 20px;
  // margin-bottom: 80px;
  margin: 80px auto 80px auto;
    max-width: 1172px;
    padding: 0 16px 0px 16px;
  .row {
    .test-engine-right {
      padding-left: 24px;
      & > h2 {
        @include text-22-fw-600;
        color: #EA2127;
      }
      & > h3 {
        @include text-22-fw-600;
      }
      & >  p {
        @include text-16-fw-400;
        & > span {
          color: #EA2127;
        }
      }
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 991px) {
  .about-boss {
    .row {
      .test-engine-left {
        max-width: 416px;
        margin: auto;
      }
      .test-engine-right {
        padding-top: 20px;
        padding-left: 0px;
        & > h2 {
          @include text-26-fw-700;
        }
        & > h3 {
          @include text-18-fw-600;
        }
      }
    }
  }
}