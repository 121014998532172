@import '../../../../common/styles/font-size';
// home-introduction
.home-introduction {
  padding: 152px 0px 76px 0px;
  .w-1440 {
    .introduction-text{
      margin: auto 0;
      padding-right: 40px;
      & > h2 {
        @include text-42-fw-700;
      }
      & > p {
        @include text-16-fw-400;
      }
    }
    .introduction-video{
      background: #EA2127;
      border-radius: 8px;
      height: fit-content;
      .introduction-anime {
        position: relative;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        .introduction-img{
          width: 100%;
          border-radius: 8px;
        }
        .introduction-icon{
          width: 132px;
          height: 128px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &:hover {
          margin: -10px 10px 10px -10px;
        }
      }
    }
  }
  
 
  
}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .home-introduction {
    .w-1440 {
      .introduction-text {
        & > h1 {
          @include text-32-fw-700;
          margin-bottom: 20px !important;
        }
        & > p {
          @include text-14-fw-400;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .home-introduction {
    // margin: auto 20px;
  }
}