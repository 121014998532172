@import '../../../../common/styles/font-size';

.ads-community {
  max-width: 1172px;
  margin: 80px auto 80px auto;
  padding: 0 16px;
  .community-above {
    position: relative;
    .community-above-wrap-image {
      display: inline-block;
      background: no-repeat url(../../../../assets/images/ads/image_bg_community.png);
      background-size: cover;
      border-radius: 8px;
      margin-left: 14px;
      .community-above-image {
        position: relative;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
          margin: 14px 14px -14px -14px;
        &> img {
          border-radius: 8px;
        }
      }
    }
    .community-above-content {
      max-width: 484px;
      background-color: #fff;
      position: absolute;
      bottom: 23.75px;
      left: 26.7px;
      padding: 23.75px 28.5px;
      border-radius: 8px;
      & > p {
        @include text-24-fw-700;
        color: #1D2939;
        margin: 18px 0px 5px 0px;
      }
      & > span {
        color: #475467;
        @include text-16-fw-400;
        & > svg {
          width: 48px;
        }
      }
      & > a {
        color: transparent;
        button {
          @include text-16-fw-400;
          display: block;
          margin-top: 12px;
          color: #fff;
          background-color: #EA2127;
          border-color: #EA2127;
          &:hover {
            color: #EA2127;
            background-color: #fff;
            border-color: #EA2127;
          }
          & > span {
            vertical-align: text-bottom;
          }
        }
      }
    }
  }
  .community-below {
    display: flex;
    .community-below-description {
      // max-width: 410px;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:first-child {
        // margin-right: 64px;
        margin-right: 40px;
      }
      .community-below-image {
        max-width: 220px;
        & > img {
          border-radius: 8px;
        }
      }
      .community-below-content {
        margin-left: 28px;
        & > p {
          color: #1D2939;
          @include text-24-fw-700;
          margin-top: 18px;
          margin-bottom: 5px;
        }
        & > span {
          color: #475467;
          @include text-16-fw-400;
          & > svg {
            width: 36px;
          }
        }
        & > a {
          color: transparent;
          button {
            @include text-16-fw-400;
            display: block;
            color: #EA2127;
            margin-top: 12px;
            // background-color: #EA2127;
            // border-color: #EA2127;
            background-color: #fff;
            border-color: #EA2127;
            &:hover {
              color: #EA2127;
              background-color: #fff;
              border-color: #EA2127;
            }
            & > span {
              vertical-align: bottom;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .ads-community {
    .community-above {
      .community-above-wrap-image {
        margin-bottom: 36px;
      }
      .community-above-content {
        position: unset;
        padding: 0px;
        & > p {
          @include text-18-fw-700;
          margin-bottom: 5px;
        }
        & > span {
          @include text-16-fw-400;
          & > svg {
            width: 24px;
          }
        }
      }
    }
    .community-below {
      flex-wrap: wrap;
      justify-content: center;
      .community-below-description {
        width: 100%;
        flex-direction: column;
        &:first-child {
          margin-bottom: 32px;
          margin-right: 0px;
        }
        .community-below-image {
          max-width: 425px;
          margin-bottom: 24px;
        }
        .community-below-content {
          margin-left: 0px;
          & > p {
            @include text-18-fw-700;
            margin-bottom: 8px;
          }
          & > span {
            @include text-16-fw-400;
            display: block;
            margin-bottom: 16px;
            & > svg {
              width: 24px;
            }
          }
          
        }
      }
    }
  }
}
