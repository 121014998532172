@import '../../../../common/styles/font-size';

.about-number {
  background: #F9FAFB;
  .row {
    margin: 80px auto auto auto;
    max-width: 1172px;
    padding: 56px 16px 56px 16px;
    justify-content: center;
    gap: 120px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .about-number {
    .row {
      gap: 90px;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .about-number {
    .row {
      gap: 36px;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .about-number {
    .row {
      gap: 0px;
    }
  }
}