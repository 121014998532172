@import '../../common/styles/font-size';

.term-page {
  & > h1 {
    padding: 80px 0px;
    @include text-44-fw-700;
    text-align: center;
    background: #101828;
    color: #ffffff;
  }
  // .hight-light-bg-text {
  //   background-color: #fbecdd;
  // }
  // .hight-light-text {
  //   color: #d44c47;
  //   fill: #d44c47;
  // }
  .italic-text {
    font-style: italic !important;
  }
  scroll-behavior: smooth;
  .term-description {
    max-width: 1172px;
    margin: 80px auto 80px auto;
    padding: 0 16px;
    .blog-article-content {
      display: flex;
      gap: 24px;
      .term-left {
        max-width: 856px;
        h2 {
          @include text-32-fw-600;
          text-align: justify;
        }
        h3 {
          @include text-22-fw-600;
          text-align: justify;
        }
        b {
          display: inline-block;
          @include text-18-fw-600;
          text-align: justify;
        }
        p {
          @include text-16-fw-400;
          text-align: justify;
        }
        ul > li {
          @include text-16-fw-400;
        }
      }
      .term-right {
        position: sticky;
        position: -webkit-sticky;
        top: 130px;
        width: 25%;
        min-width: 335px;
        padding: 8px 24px;
        height: fit-content;
        & >  h3 {
          @include text-28-fw-700;
        }
        & >a {
          display: inline-block;
          text-decoration: none;
          color: #101828;
          margin-left: 24px;
          & > p {
            padding: 8px 20px;
            border-left: 2px solid #D0D5DD;
            @include text-16-fw-700;
            &:hover
            {
              border-left: 2px solid #ea2127!important;
              transition: all .5s;
              color: #ea2127;
            }
          }
        }
        .tab-active {
          border-left: 2px solid #ea2127!important;
          transition: all .5s;
          color: #ea2127;
        }
      }   
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .term-page {
    .term-description {
      .blog-article-content {
        flex-wrap: wrap;
      }
      .term-right {
        position: unset !important;
        min-width: 300px !important;
        padding: unset !important;
      }
      .term-left {
        max-width: 300px !important;
      }
    }
  }
}