// @import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700&display=swap');
@import './font-size';

html {
  margin: 0;
  font-size: 62.5%;
  scroll-behavior: smooth;
}
:root {
  //add
  --rem-px-ratio: 1;
  --font-family: 'Manrope';
  --font-archia: 'Archia';
  --font-BROmegaVN: 'BR Omega VN';
}

body {
  color: #101828;
}

h1,h2,h3,h4,h5,h6 {
  font-family: var(--font-archia) !important;
}

.font-archia {
  font-family: var(--font-archia) !important;
}

.font-BROmegaVN {
  font-family: var(--font-BROmegaVN) !important;
}

.Toastify {
  @include text-14-fw-400;
}

.btn {
  @include text-16-fw-700;
}

@font-face {
  font-family: 'Archia';
  src: local('Archia'), url(../fonts/Archia-Regular.woff) format('woff');
}

img {
  max-width: 100%;
}
p {
  margin: 0;
}

/* font Archia */
@font-face {
  font-family: 'Archia';
  src: url('../fonts/../fonts/Archia-Light.eot');
  src: url('../fonts/Archia-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Archia-Light.woff2') format('woff2'),
      url('../fonts/Archia-Light.woff') format('woff'),
      url('../fonts/Archia-Light.ttf') format('truetype'),
      url('../fonts/Archia-Light.svg#Archia-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Archia';
  src: url('../fonts/Archia-Regular.eot');
  src: url('../fonts/Archia-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Archia-Regular.woff2') format('woff2'),
      url('../fonts/Archia-Regular.woff') format('woff'),
      url('../fonts/Archia-Regular.ttf') format('truetype'),
      url('../fonts/Archia-Regular.svg#Archia-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Archia';
  src: url('../fonts/Archia-Bold.eot');
  src: url('../fonts/Archia-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Archia-Bold.woff2') format('woff2'),
      url('../fonts/Archia-Bold.woff') format('woff'),
      url('../fonts/Archia-Bold.ttf') format('truetype'),
      url('../fonts/Archia-Bold.svg#Archia-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Archia';
  src: url('../fonts/Archia-Thin.eot');
  src: url('../fonts/Archia-Thin.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Archia-Thin.woff2') format('woff2'),
      url('../fonts/Archia-Thin.woff') format('woff'),
      url('../fonts/Archia-Thin.ttf') format('truetype'),
      url('../fonts/Archia-Thin.svg#Archia-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Archia';
  src: url('../fonts/Archia-SemiBold.eot');
  src: url('../fonts/Archia-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Archia-SemiBold.woff2') format('woff2'),
      url('../fonts/Archia-SemiBold.woff') format('woff'),
      url('../fonts/Archia-SemiBold.ttf') format('truetype'),
      url('../fonts/Archia-SemiBold.svg#Archia-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Archia';
  src: url('../fonts/Archia-Medium.eot');
  src: url('../fonts/Archia-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Archia-Medium.woff2') format('woff2'),
      url('../fonts/Archia-Medium.woff') format('woff'),
      url('../fonts/Archia-Medium.ttf') format('truetype'),
      url('../fonts/Archia-Medium.svg#Archia-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* font BROmegaVN */
@font-face {
  font-family: 'BR Omega VN';
  src: url('../fonts/BROmegaVN/BROmegaVN-Bold.eot');
  src: url('../fonts/BROmegaVN/BROmegaVN-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/BROmegaVN/BROmegaVN-Bold.woff2') format('woff2'),
      url('../fonts/BROmegaVN/BROmegaVN-Bold.woff') format('woff'),
      url('../fonts/BROmegaVN/BROmegaVN-Bold.ttf') format('truetype'),
      url('../fonts/BROmegaVN/BROmegaVN-Bold.svg#BROmegaVN-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BR Omega VN';
  src: url('../fonts/BROmegaVN/BROmegaVN-Black.eot');
  src: url('../fonts/BROmegaVN/BROmegaVN-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/BROmegaVN/BROmegaVN-Black.woff2') format('woff2'),
      url('../fonts/BROmegaVN/BROmegaVN-Black.woff') format('woff'),
      url('../fonts/BROmegaVN/BROmegaVN-Black.ttf') format('truetype'),
      url('../fonts/BROmegaVN/BROmegaVN-Black.svg#BROmegaVN-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BR Omega VN';
  src: url('../fonts/BROmegaVN/BROmegaVN-LightItalic.eot');
  src: url('../fonts/BROmegaVN/BROmegaVN-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/BROmegaVN/BROmegaVN-LightItalic.woff2') format('woff2'),
      url('../fonts/BROmegaVN/BROmegaVN-LightItalic.woff') format('woff'),
      url('../fonts/BROmegaVN/BROmegaVN-LightItalic.ttf') format('truetype'),
      url('../fonts/BROmegaVN/BROmegaVN-LightItalic.svg#BROmegaVN-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'BR Omega VN';
  src: url('../fonts/BROmegaVN/BROmegaVN-Light.eot');
  src: url('../fonts/BROmegaVN/BROmegaVN-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/BROmegaVN/BROmegaVN-Light.woff2') format('woff2'),
      url('../fonts/BROmegaVN/BROmegaVN-Light.woff') format('woff'),
      url('../fonts/BROmegaVN/BROmegaVN-Light.ttf') format('truetype'),
      url('../fonts/BROmegaVN/BROmegaVN-Light.svg#BROmegaVN-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BR Omega VN';
  src: url('../fonts/BROmegaVN/BROmegaVN-BoldItalic.eot');
  src: url('../fonts/BROmegaVN/BROmegaVN-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/BROmegaVN/BROmegaVN-BoldItalic.woff2') format('woff2'),
      url('../fonts/BROmegaVN/BROmegaVN-BoldItalic.woff') format('woff'),
      url('../fonts/BROmegaVN/BROmegaVN-BoldItalic.ttf') format('truetype'),
      url('../fonts/BROmegaVN/BROmegaVN-BoldItalic.svg#BROmegaVN-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'BR Omega VN';
  src: url('../fonts/BROmegaVN/BROmegaVN-BlackItalic.eot');
  src: url('../fonts/BROmegaVN/BROmegaVN-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/BROmegaVN/BROmegaVN-BlackItalic.woff2') format('woff2'),
      url('../fonts/BROmegaVN/BROmegaVN-BlackItalic.woff') format('woff'),
      url('../fonts/BROmegaVN/BROmegaVN-BlackItalic.ttf') format('truetype'),
      url('../fonts/BROmegaVN/BROmegaVN-BlackItalic.svg#BROmegaVN-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'BR Omega VN';
  src: url('../fonts/BROmegaVN/BROmegaVN-Regular.eot');
  src: url('../fonts/BROmegaVN/BROmegaVN-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/BROmegaVN/BROmegaVN-Regular.woff2') format('woff2'),
      url('../fonts/BROmegaVN/BROmegaVN-Regular.woff') format('woff'),
      url('../fonts/BROmegaVN/BROmegaVN-Regular.ttf') format('truetype'),
      url('../fonts/BROmegaVN/BROmegaVN-Regular.svg#BROmegaVN-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BR Omega VN';
  src: url('../fonts/BROmegaVN/BROmegaVN-Italic.eot');
  src: url('../fonts/BROmegaVN/BROmegaVN-Italic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/BROmegaVN/BROmegaVN-Italic.woff2') format('woff2'),
      url('../fonts/BROmegaVN/BROmegaVN-Italic.woff') format('woff'),
      url('../fonts/BROmegaVN/BROmegaVN-Italic.ttf') format('truetype'),
      url('../fonts/BROmegaVN/BROmegaVN-Italic.svg#BROmegaVN-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

