@import '../../common/styles/font-size';

.email-page {
  background: linear-gradient(96.44deg,#c5d5ff,#e2ffea 51.56%,#bdf3cc);
  padding: 30px 70px;
  .email-container {
    max-width: 1200px;
    margin: 0 auto;
    .email-form-group {
      display: flex;
      gap: 10px;
      .email-form-title {
        margin: auto 0;
        width: 60px;
        @include text-16-fw-700;
      }
      .email-form-input {
        box-shadow: 0 7px 14px #a2a6d166;
        border-radius: 5px;
        border: 0;
        width: 510px;
        height: 40px;
        &:focus {
          outline: none;
          box-shadow: inset 0 0 0 1px #90caf9;
        }
      }
      .email-wrap-user {
        display: flex;
        cursor: pointer;
        & > input {
          margin: auto 20px auto 0px;
          width: 20px;
          height: 20px;
          accent-color: rgb(39, 118, 216);
          &::marker {
            content: counter(list-item) "› ";
            color: hotpink;

          }
        }
        
        & > p {
          margin: auto 0;
          @include text-16-fw-700;
        }
      }
    }
    .email-btn {
      background-color: #00a3ff;
      color: #fff;
      border: none;
      align-items: center;
      border-radius: 3px;
      cursor: pointer;
      display: inline-flex;
      font-size: 14px;
      height: 40px;
      justify-content: center;
      line-height: 40px;
      padding: 0 20px;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
    }
  }
}