@import '../../../../common/styles/font-size';
// home-product
.home-product{
  background: url('../../../../assets/images/image_product.png') center center no-repeat;
  background-size: cover;
  padding: 60px 0px;
  & > h3 {
    color: #ffffff;
    text-align: center;
    @include text-42-fw-700;
  }
  & > p {
    color: #ffffff;
    text-align: center;
    @include text-14-fw-700;
  }
  .wrap-product{
    display: flex;
    justify-content: center;
    align-items: center;
    .product{
      width: 371px;
      // background-color: #EA2127;
      border-radius: 9px;
      .product-animations {
        background-color: #ffffff; 
        border-radius: 12px;
        padding: 20px 30px 30px 30px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        .product-header {
          text-align: center;
          .product-tag {
            margin: auto;
            @include text-16-fw-600;
            width: fit-content;
            padding: 4px 12px;
            border-radius: 32px;
          }
          & > h3 {
            @include text-28-fw-700;
          }
          & > p {
            @include text-16-fw-600;
          }
        }
        .product-content {
          margin-top: 40px;
          .product-benefit{
            @include text-14-fw-400;
            display: flex;
            gap: 16px;
          }
          .wrap-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            .product-btn{
              @include text-16-fw-700;
              background-color: #EA2127;
              color: #ffffff;
              padding: 8px 12px;
              border: 1px solid #EA2127;
              border-radius: 4px;
              &:hover {
                background: #ffffff;
                color: #EA2127;
                border: 1px solid #EA2127;
              }
            }
          }
          
        }
        // &:hover {
        //   margin: -10px 10px 10px -10px;
        // }
      }
    } 
    .product-enterprise {
      .product-animations {
        padding: 20px 30px 30px 30px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }  
  }
}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .home-product {
      & > h3 {
        @include text-36-fw-700;
        margin-bottom: unset !important;
      }
      & > p {
        color: #ffffff;
        text-align: center;
        @include text-12-fw-400;
      }
      .wrap-product{
        .product {
          .product-animations {
            .product-header {
              & > h3 {
                @include text-24-fw-700;
              }
              & > p {
                @include text-14-fw-700;
              }
            }
            .product-content {
              .product-benefit{
                @include text-14-fw-400;
              }
            }
          }
        }
      }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .home-product {
    // margin: auto 20px;
  }
}