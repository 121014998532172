@import '../../../../common/styles/font-size';


.download-header {
  margin-bottom: 72px;
  .header-top-description {
    & > h1 {
      @include text-44-fw-700;
    }
    & > ul > li {
        @include text-16-fw-400;
        & > span {
          color: #ea2127;
        }
        & > a {
          @include text-16-fw-400;
          color: #ea2127;
          text-decoration: none;
        }
    }
  }
  .header-community {
    padding: 36px 40px;
    border-radius: 16px;
    background: url('../../../../assets/images/download/bg_top_header.png') center center no-repeat;
    background-size: cover;
    color: #ffffff;
    & > h2 {
      @include text-24-fw-700;
    }
    & > span {
      @include text-16-fw-400;
      color: #ea2127;
    }
    &> p {
      @include text-16-fw-400;
      & > span {
        color: #ea2127;
      }
    }
  }
  
}

@media screen and (min-width: 375px) and (max-width: 991px) {
  .download-header {
    .header-top-description {
      & > h1 {
        @include text-26-fw-700;
      }
    }
    .header-community {
      padding: 16px;
    }
  }
}