
@import '../../../../common/styles/font-size';

.pricing-question {
  background: #F2F4F7;
  .pricing-question-container {
    max-width: 1172px;
    margin: 0px auto 0px auto;
    padding: 80px 16px 185px 16px;
    & > h2 {
      @include text-42-fw-700;
    }
    & > p {
      @include text-16-fw-600;
      color: #667085;
    }
    .accordion {
      .accordion-expanded {
        background: #101828;
        color: #ffffff;
      }
      .accordion-item {
        padding: 30px 42px;
        border: 2px solid #D0D5DD;
        border-radius: 12px;
  
        & > button {
          position: relative;
          display: block;
          text-align: left;
          width: 100%;
          border: none;
          background: none;
          outline: none;
          cursor: pointer;
          }
          h3 {
            @include text-18-fw-600;
            padding-right: 20px;
            color: #101828;
          }
          .icon {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
  
        button[aria-expanded='true'] {
          color: #ffffff;
          + .accordion-content {
            opacity: 1;
            max-height: 9em;
            transition: all 200ms linear;
            will-change: opacity, max-height;
          }
          h3 {
            color: #ffffff;
          }
        }
  
        .accordion-content {
          opacity: 0;
          max-height: 0;
          overflow: hidden;
          transition: opacity 200ms linear, max-height 200ms linear;
          will-change: opacity, max-height;
          p {
            @include text-16-fw-600;
          }
        }
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 991px) {
  .pricing-question {
    .pricing-question-container {
      & > h2 {
        @include text-26-fw-700;
      }
      & > p {
        @include text-16-fw-400;
      }
      .accordion {
        .accordion-item {
          padding: 30px 20px;
        }
      }
    }
  }
  
}
