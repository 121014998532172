@import '../../../../common/styles/font-size';

.ads-feature {
  max-width: 1172px;
  margin: 80px auto 0px auto;
  padding: 0 16px;
  position: relative;
  & > p {
    @include text-40-fw-700;
  }
 
  .ads-feature-line,
  .ads-feature-line-enterprise {
    display: flex;
    align-items: center;
    gap: 18px;
    margin: 18px 0px 46px 0px;
    @include text-24-fw-700;
    .line {
      height: 4px;
      border-radius: 16px;
      background: url('../../../../assets/images/ads/image_line_feature.png');
      background-size: cover;
    }
  }
  .ads-feature-line {
    .line {
      width: 60%;
    }
  }
  .ads-feature-line-enterprise {
    .line {
      width: 90%;
    }
  }
  .ads-feature-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 46px 0px;

    .ads-feature-item {
      width: 30%;
      text-align: center;
      & > p {
        @include text-24-fw-700;
        margin: 15px 0px 6px 0px;
      }
      & > span {
        color: #667085;
        @include text-16-fw-400;
      }
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 991px) {
  .ads-feature {
    max-width: 375px;
    & > p {
      @include text-20-fw-700;
      margin-bottom: 55px;
    }
    .ads-feature-line,
    .ads-feature-line-enterprise {
      display: none;
    }
    .ads-feature-row {
      gap: unset;
      .ads-feature-item {
        width: 100%;
        margin-bottom: 32px;
        & > p {
          @include text-16-fw-700;
        }
        & > span {
          color: #667085;
          @include text-14-fw-400;
        }
      }
    }
  }
}