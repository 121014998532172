@import '../../../../common/styles/font-size';


.profile-introduction {
  background: #FCFCFD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 28px 32px;
  margin-bottom: 24px;
  .introduction-top {
    display: flex;
    gap: 24px;
    .introduction-top-img {
      width: 25%;
      position: relative;

      & > input {
        position: absolute;
        left: 0;
        opacity: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
      & > img {
        width: 229px;
        border-radius: 8px;
        object-fit: cover;
      }
      .introduction-top-choose-img {
        position: absolute;
        display: flex;
        @include text-14-fw-600;
        color: #FFFFFF;
        width: 229px;
        height: 100%;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        /* Black see-through */
        position: absolute;
        opacity: 0;
        bottom: 0; 
        background: rgb(0, 0, 0);
        background: rgba(0, 0, 0, 0.5); 
        transition: .5s ease;
        & > p {
          border: 1px solid #FFFFFF;
          padding: 8px 12px;
          border-radius: 4px;
        }
      }
      &:hover {
        .introduction-top-choose-img {
          opacity: 1;
        }
      }
    }
    .introduction-top-content {
      width: 75%;
      .introduction-top-content-person {
        & > h3 {
          @include text-28-fw-700;
        }
        & > p {
          @include text-16-fw-500;
          color: #667085;
        }
      }
      .introduction-top-content-work {
        display: flex;
        
        gap: 16px;
        .introduction-top-content-work-item {
          width: 50%;
          background: #F2F4F7;
          border-radius: 8px;
          padding: 20px;
          & > p:nth-child(1) {
            @include text-16-fw-600;
          }
          & > p:nth-child(2) {
            @include text-16-fw-400;
          }
        }
      }
    }
  }

  .introduction-tab {
    display: flex;
    .tab-item {
      @include text-16-fw-700;
      color: #667085;
      width: fit-content;
      margin: auto 0;
      text-align: center;
      cursor: pointer;
      padding: 0px 16px 16px 16px;
      border-bottom: 2px solid #D0D5DD;
    }
    .tab-active{
      border-bottom: 2px solid red;
    }
  }
}

/*Mobile styles*/
@media screen and (min-width: 320px) and (max-width: 991px) {
  .profile-introduction {
   
    .introduction-top {
      flex-direction: column;
      .introduction-top-img {
        width: 100%;
        text-align: center;
      }
      .introduction-top-content {
        width: 100%;
        .introduction-top-content-person {
          text-align: center;
        }
      }
    }
  }
}