@import '../../../../common/styles/font-size';

.about-why {
  background: #101828;
  .why-container {
    margin: auto;
    max-width: 1172px;
    padding: 0px 0px 1px 0px;
    justify-content: center;
    .why-list-item {
      margin-bottom: -1px;
      .why-item {
        color: #ffffff;
        padding: 20px 20px 0px 20px;
        & > h3 {
          @include text-28-fw-700;
        }
        & > p {
          @include text-16-fw-400;
        }
      }
      .why-bg-white-item{
        padding: 20px 20px 0px 20px;
        background: #ffffff;
        & > h3 {
          @include text-28-fw-700;
          color: #ea2127;
        }
        & > p {
          @include text-16-fw-400;
        }
      }
      .why-bg-red-item{
        padding: 30px 30px 0px 30px;
        background: #ea2127;
        & > h2 {
          @include text-60-fw-700;
          color: #ffffff;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .about-why {
    .why-container {
      .why-list-item {
        justify-content: center;
        .why-bg-red-item{
          & > h2 {
            @include text-48-fw-700;
            color: #ffffff;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .about-why {
    .why-container {
      .why-list-item {
        justify-content: center;
        .why-item {
          color: #ffffff;
          padding: 3px 10px 0px 10px;
          & > h3 {
            @include text-20-fw-700;
          }
          & > p {
            @include text-14-fw-400;
          }
        }
        .why-bg-red-item{
          padding: 20px 20px 0px 20px;
          & > h2 {
            @include text-36-fw-700;
            color: #ffffff;
          }
        }
        .why-bg-white-item{
          padding: 10px;
          & > h3 {
            @include text-20-fw-700;
          }
          & > p {
            @include text-14-fw-400;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
  .about-why {
    .why-container {
      .why-list-item {
        .why-item,
        .why-bg-white-item{
          padding: 20px 20px 0px 20px;
          & > h3 {
            @include text-28-fw-700;
          }
          & > p {
            @include text-16-fw-400;
          }
        }
        .why-bg-red-item{
          padding: 20px 20px 0px 20px;
          & > h2 {
            @include text-60-fw-700;
            color: #ffffff;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 567px) {
  .about-why {
    .why-container {
      .why-list-item {
        .why-item,
        .why-bg-white-item{
          padding: 10px 10px 0px 10px;
          & > h3 {
            @include text-18-fw-600;
          }
          & > p {
            @include text-14-fw-400;
          }
        }
        .why-bg-red-item{
          padding: 15px 15px 0px 15px;
          & > h2 {
            @include text-36-fw-700;
            color: #ffffff;
          }
        }
      }
    }
  }
}