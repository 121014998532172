@import '../../../common/styles/font-size';

.insights-article-page {
  max-width: 1172px;
  margin: 80px auto 80px auto;
  padding: 0 16px;
  & > h1 {
    @include text-44-fw-700;
  }
  .blog-tag {
    width: fit-content;
    padding: 4px 12px;
    background: #ffffff;
    border-radius: 14px;
  }

  .blog-article-header {
    display: flex;
    background: url('../../../assets/images/image_graywood_grain.png') center center no-repeat, #F2F2F2;
    background-size: cover;
    border-radius: 20px;
    gap: 24px;
    padding: 40px;
    .blog-article-left{
      flex: 1;
      .blog-article-tag-wrap {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        @include text-14-fw-400;
      }

      & > h1 {
        @include text-32-fw-600;
      }

      & > p {
        @include text-16-fw-700;
        color: #667085;
      }
    }
    .blog-article-right {
      max-width: 506px;
      & > img {
        width: 100%;
      }
    }
  }
  .blog-article-content {
    display: flex;
    gap: 24px;
    .blog-article-content-left {
      max-width: 856px;
      & > p {
        @include text-16-fw-400;
        margin-bottom: 8px;
        text-align: justify;
      }
      & > ul > li {
        @include text-16-fw-400;
      }
    }
    .blog-article-content-right {
      position: sticky;
      top: 100px;
      width: 415px;
      min-width: 335px;
      padding: 8px 24px;
      height: fit-content;
      background: #F2F2F2;
      border-radius: 8px;
      .blog-article-toc {
        & > ul {
          padding: 0px;
          li {
            list-style-type: none;
            & > a {
              @include text-16-fw-400;
              text-decoration: none;
              color: #101828;
            }
          }
        }
      }
    }
  }
  .blog-article-social-network {
    & > p {
      @include text-16-fw-400;
    }
    .wrap-icons {
      background-color: #F2F2F2;
      border-radius: 20px;
      padding: 20px;
      width: fit-content;
    }
  }
}

/*Mobile styles*/
@media screen and (min-width: 375px) and (max-width: 991px) {
  .insights-article-page {
    & > h1 {
      @include text-36-fw-700;
    }
    .blog-article-header {
      flex-direction: column-reverse;
      padding: 16px;
      gap: 0px;
      .blog-article-left{
        .divider-48 {
          margin-bottom: 20px !important;
        }
        & > h1 {
          @include text-24-fw-700;
        }
        & > p {
          @include text-14-fw-400;
        }
      }
      .blog-article-right {
        width: unset;
        margin: auto;
        margin-bottom: 24px;
      }
  
    }
    .blog-article-content {
      flex-direction: column-reverse;
      gap: 0px;
      .blog-article-content-right {
        position: unset;
        width: unset;
        max-width: 415px;
        margin-bottom: 24px;
      }
    }
  }
}