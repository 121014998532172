@import '../../../../common/styles/font-size';
// about-recognition
.about-recognition {
  // padding: 0px 72px 140px;
  overflow: hidden;
  padding-bottom: 80px;
  margin-bottom: 60px;
  background: #101828;
  .recognition-text {
    margin-top: 60px;
    text-align: center;
    color: #ffffff;
    & > h2 {
      @include text-42-fw-700;
    }
    & > p {
      @include text-14-fw-400;
    }
  }
  .recognition-slides {
    margin: auto;
    max-width: 936px;
    padding: 0px 16px;
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            & > div {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .slick-dots {
        & > li {
          margin-right: 8px;
          & > button {
            width: 20px;
            height: 4px;
            background-color: #d9d9d9;
            border-radius: 4px;
            padding: 0;
            &::before {
              display: none;
            }
          }
        }
        .slick-active {
          & > button {
            background: #ea2127;
          }
        }
      }
    }

    .recognition-row {
      max-width: 280px;
      height: 320px;
      padding: 20px 16px;
      background: url("../../../../assets/images/image_mask_circle.png") center
        center no-repeat #ffffff;
      background-size: cover;
      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
        0px 12px 16px -4px rgba(16, 24, 40, 0.08);
      border-radius: 8px;
      .recognition-logo {
        width: 66px;
      }
      & > h3 {
        @include text-22-fw-600;
      }
      & > p {
        @include text-16-fw-400;
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 991px) {
  .about-recognition {
    .recognition-slides { 
      max-width: 624px;
    }
  }
}


@media screen and (min-width: 375px) and (max-width: 991px) {
  .about-recognition {
    .recognition-text {
      & > h2 {
        @include text-26-fw-700;
      }
    }
  }
}
