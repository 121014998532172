@import '../../../common/styles/font-size';

.m-ads-feature {
  padding: 0px 16px;
  & > p {
    @include text-24-fw-700;
    margin-top: 20px;
  }
  .ads-feature-line,
  .ads-feature-line-enterprise {
      display: flex;
      align-items: center;
      gap: 18px;
      margin: 18px 0px 46px 0px;
      @include text-24-fw-700;
      .line {
        width: 100%;
        height: 4px;
        border-radius: 16px;
        background: url('../../../assets/images/ads/image_line_feature.png');
        background-size: cover;
      }
    }

  .feature-slides {
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            & > div {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .slick-dots {
        position: unset;
        & > li {
          margin-right: 8px;
          & > button {
            width: 6px;
            height: 6px;
            background-color: #d9d9d9;
            border-radius: 4px;
            padding: 0;
            &::before {
              display: none;
            }
          }
        }
        .slick-active {
          & > button {
            background: #ea2127;
          }
        }
      }
    }

    .feature-wrap-item {
      // width: unset !important;
      max-width: 425px;
      // height: 320px;
      // padding: 20px 16px;
      // box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      //   0px 12px 16px -4px rgba(16, 24, 40, 0.08);
      // border-radius: 8px;
      // & > h3 {
      //   @include text-26-fw-700;
      // }
      // & > p {
      //   @include text-16-fw-400;
      // }
      
      .ads-feature-item {
        max-width: 425px;
        margin-bottom: 24px;
      // height: 320px;
        text-align: center;
        // width: 100%;
        & > p {
          margin: 24px auto 8px auto;
          @include text-18-fw-700;
        }
        & > span {
          color: #667085;
          @include text-16-fw-400;
        }
      }
    }
  }
}