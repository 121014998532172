@import '../../../../common/styles/font-size';

.events-blog-page {
  max-width: 1172px;
  margin: 80px auto 134px auto;
  padding: 0 20px;
  // .more-wrap-item {
  //   display: flex;
  //   flex-wrap: wrap;
  //   column-gap: 24px;
  //   row-gap: 68px;
    
  // }
  .insights-tag {
    @include text-14-fw-400;
    width: fit-content;
    padding: 4px 12px;
    background: #EAECF0;
    border-radius: 14px;
  }
  & > h1 {
    @include text-44-fw-700;
  }

  & > hr {
    margin-top: 0px;
    margin-bottom: 40px;
    height: 2px;
  }

  .more-item {
    max-width: 856px;
    display: flex;
    gap: 20px;
    margin: auto auto 40px auto;
    box-shadow: rgba(0, 0, 0, 0.161) 0px 1px 4px;
    border-radius: 20px;
    .insights-blog-img {
      max-width: 384px;
      img {
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
    }
    .insights-blog-content {
      max-width: 452px;
      margin-top: 20px;
      & > a {
        color: #101828;
        text-decoration: none;
        & > img {
          border-radius: 20px;
          width: 100%;
        }
        & > h5 {
          @include text-18-fw-600;
        }
        &:hover {
          color: #087cce;
        }
      }
      .more-short-desc {
        color: #667085;
        @include text-16-fw-400;
      }
    }
  }

}

/*Mobile styles*/
@media screen and (min-width: 375px) and (max-width: 767px) {
  .events-blog-page {
    & > h1 {
      @include text-36-fw-700;
    }
    .more-item {
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      max-width: 384px;
      gap: unset;
      .insights-blog-content {
        margin: 0px;
      }
    }
  }
}