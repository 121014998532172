@import '../../../../common/styles/font-size';

.ads-ecosystem {
  width: 100%;
  margin: 80px auto 110px auto;
  // background: no-repeat url(../../../../assets/images/ads/image_bg_ecosystem.png);
  // background-size: contain;
  .ads-container {
    max-width: 1172px;
    margin: 0 auto;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .ads-ecosystem-img {
      position: absolute;
      max-width: 900px;
      top: 80px;
      left: -45%;
    }
    .ads-ecosystem-left {
      width: 40%;
      margin-right: 30px;
      position: relative;
      &> h3 {
        @include text-40-fw-700;
      }
      &> p {
        @include text-16-fw-400;
        color: #475467;
      }
      & > a {
        button {
          @include text-16-fw-400;
          color: #fff;
          background-color: #EA2127;
          border-color: #EA2127;
          & > span {
            vertical-align: bottom;
          }
          &:hover {
            color: #EA2127;
            background-color: #fff;
            border-color: #EA2127;
          }
        }
       
      }
    }
    .ads-ecosystem-right {
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      .ecosystem-item-bg-1 {
        display: inline-block;
        width: 50%;
        border-radius: 16px;
        margin-right: 30px;
        background: no-repeat url(../../../../assets/images/ads/image_bg_ecosystem_item.png);
        background-size: cover;
        &:hover {
          color: #EA2127;
        }
        & > a {
          text-decoration: none;
          color: unset;
          .ecosystem-item-management {
            background-color: #ffffff;
            height: fit-content;
            padding: 30px 34px;
            border-radius: 16px;
            position: relative;
            
            position: relative;
            transition: all 0.2s ease-in-out;
            margin: 12px 12px -12px -12px;
            & > span {
              & > svg {
                width: 120px;
              }
            }
            & > h3 {
              margin-top: 75px;
              @include text-24-fw-700;
            }
            &> p {
              @include text-16-fw-400;
              color: #475467;
            }
            &:before {
              content: "";
              position: absolute;
              inset: 0;
              padding: 2px;
              border-radius: 16px;
              background: linear-gradient(137.6deg, #C1233D 6.02%, #282D88 77.35%);
              -webkit-mask: 
                 linear-gradient(#fff 0 0) content-box, 
                 linear-gradient(#fff 0 0);
                      mask: 
                 linear-gradient(#fff 0 0) content-box, 
                 linear-gradient(#fff 0 0);
              -webkit-mask-composite: xor;
                      mask-composite: exclude;
            }
          }
        }
       
      }
      .ecosystem-item {
        width: 50%;
        h3 {
          margin-top: 54px;
          @include text-24-fw-700;
        }
        p {
          @include text-16-fw-400;
          color: #475467;
        }
        
        .ecosystem-item-bg-2 {
          display: inline-block;
          background: no-repeat url(../../../../assets/images/ads/image_bg_ecosystem_item.png);
          background-size: cover;
          border-radius: 16px;
          margin-bottom: 30px;
          &:first-child {
            width: 86%;
          }
          & > a {
            text-decoration: none;
            color: unset;
            .ecosystem-item-farm, .ecosystem-item-engine{
              background-color: #ffffff;
              border-radius: 16px;
              margin: 12px 12px -12px -12px;
              padding: 30px;
              position: relative;
              &:hover {
                color: #EA2127;
              }
              &:before {
                content: "";
                position: absolute;
                inset: 0;
                padding: 2px;
                border-radius: 16px;
                background: linear-gradient(137.6deg, #C1233D 6.02%, #282D88 77.35%);
                -webkit-mask: 
                   linear-gradient(#fff 0 0) content-box, 
                   linear-gradient(#fff 0 0);
                        mask: 
                   linear-gradient(#fff 0 0) content-box, 
                   linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                        mask-composite: exclude;
              }
            }
            .ecosystem-item-farm {
              & > span {
                & > svg {
                  width: 95px;
                }
              }
            } 
            .ecosystem-item-engine{
              & > span {
                & > svg {
                  width: 100px;
                }
              }
            }
          }
        }
       
      }
      
     
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .ads-ecosystem {
    margin: 28px auto 80px auto;
    .ads-container {
      flex-direction: column;
      padding: 0 16px 0 28px;
      .ads-ecosystem-img {
        display: none;
      }
      .ads-ecosystem-left {
        width: 100%;
        margin-right: 24px;
        margin-bottom: 32px;
        &> h3 {
          @include text-24-fw-700;
          color: #1D2939;
        }
        &> p {
          color: #475467;
          @include text-16-fw-400;
          margin-bottom: 16px;
        }
      }
      .ads-ecosystem-right {
        width: 100%;
        max-width: 335px;
        flex-direction: column;
        margin-bottom: 32px;
        .ecosystem-item-bg-1 {
          width: 100%;
          margin-right: 0px;
          margin-bottom: 36px;
          & > a {
            .ecosystem-item-management {
              & > span {
                & > svg {
                  height: 38px;
                  width: 100px;
                }
              }
              & > h3 {
                @include text-24-fw-700;
              }
              & > p {
                @include text-16-fw-400;
              }
            }
          }
          
        }
        .ecosystem-item {
          width: 100%;
          h3 {
            @include text-24-fw-700;
          }
          p {
            @include text-16-fw-400;
          }
          .ecosystem-item-bg-2 {
            &:first-child {
              width: 100%;
              margin-bottom: 36px;
            }
            & > a {
              .ecosystem-item-farm,
              .ecosystem-item-engine {
                & > span {
                  & > svg {
                    height: 38px;
                  }
                }
              }
              .ecosystem-item-farm {
                & > h3 {
                  margin-top: 103px;
                }
              }
            }
          }
        }
      }
      
    }
  }
}