@import '../../common/styles/font-size';

.contact-us-page {
  background-color: #F2F2F2;
  margin: 0 auto;
  padding: 80px 0px;
  .contact-required {
    color: #EA2127;
  }
  .contact-header {
    text-align: center;
    & > h1 {
      @include text-44-fw-700;
    }
    &> p {
      @include text-16-fw-600;
    }
  }
  .contact-wrap {
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 20px;
    max-width: 1140px;
    display: flex;
    padding: 20px;
    gap: 24px;
    margin: 0 auto;
    .contact-left {
      width: 38.4%;
      position: relative;
      background: url('../../assets/images/image_redwood_grain.png') center center no-repeat;
      background-size: cover;
      background-blend-mode: multiply, normal;
      color: #FFFFFF;
      padding: 40px;
      border-radius: 20px;
      .contact-item {
        & > h2 {
          @include text-32-fw-600;
        }
        & > h3 {
          @include text-16-fw-700;
        }
        & > p {
          @include text-16-fw-400;
          .contact-icon {
            vertical-align: text-bottom;
            margin-right: 8px;
          } 
        }
      }
      .contact-icon-social {
        position: absolute;
        bottom: 45px;
        left: 40px;
      }
    }
    .contact-right {
      width: 752px;
      padding: 40px;
      .contact-form-field {
        label {
          @include text-12-fw-400;
        }
        input {
          width: 100%;
          @include text-16-fw-400;
          outline: none;
          border-width: 0 0 2px;
          border-color: #D0D5DD;
          padding: 8px 0px;
          &:focus {
            border-color: #EA2127;
          }
          &::placeholder {
            @include text-16-fw-400;
          }
        }

        textarea {
          resize: none;
          @include text-16-fw-400;
          width: 100%;
          border-width:  0 0 2px;
          border-color: #D0D5DD;
          outline: none;
          &:focus {
            border-color: #EA2127;
          }
          &::placeholder {
            @include text-16-fw-400;
          }
        }
      }
      
      .contact-wrap-field {
        width: 100%;
        display: flex;
        gap: 41px;
        .contact-row {
          width: 50%;
        }
      }

      .contact-captcha {
        div {
          height: unset !important;
          border-radius: 20px;
        }
      }

      .contact-btn {
        @include text-16-fw-700;
        background-color: #EA2127;
        color: #ffffff;
        padding: 8px 12px;
        border: none;
        border-radius: 4px;
      }
    }
  }
}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1354px) {
  .contact-us-page {
    padding: 80px 20px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact-us-page {

  }
}
/*Mobile styles*/
@media screen and (min-width: 375px) and (max-width: 991px) {
  .contact-us-page {
    padding: 80px 16px;
    .contact-header {
      text-align: center;
      & > h1 {
        @include text-28-fw-700;
      }
      &> p {
        @include text-16-fw-400;
      }
    }
    .contact-wrap {
      flex-direction: column;
      padding: 20px;
      .contact-left {
        width: 100%;
        min-height: 500px;
        padding: 20px;
        .divider-68 {
          margin-bottom: 40px !important;
        }
        .contact-item {
          & > h3 {
            @include text-28-fw-700;
          }
          & > h6 {
            @include text-16-fw-700;
          }
          & > p {
            @include text-16-fw-400;
            .contact-icon {
              vertical-align: text-bottom;
              margin-right: 8px;
            } 
          }
        }
        .contact-icon-social {
          left: 20px;
        }
      }
      .contact-right {
        width: 100%;
        padding: 0;
        .contact-wrap-field {
          flex-wrap: wrap;
          .contact-row {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .contact-us-page {
    padding: 80px 16px;
    .contact-header {
      text-align: center;
      & > h1 {
        @include text-28-fw-700;
      }
      &> p {
        @include text-16-fw-400;
      }
    }
    .contact-wrap {
      flex-direction: column;
      padding: 15px;
      .contact-left {
        width: 100%;
        min-height: 500px;
        padding: 20px;
        .divider-68 {
          margin-bottom: 40px !important;
        }
        .contact-item {
          & > h3 {
            @include text-28-fw-700;
          }
          & > h6 {
            @include text-16-fw-700;
          }
          & > p {
            @include text-16-fw-400;
            .contact-icon {
              vertical-align: text-bottom;
              margin-right: 8px;
            } 
          }
        }
        .contact-icon-social {
          left: 20px;
        }
      }
      .contact-right {
        width: 100%;
        padding: 0;
        .contact-wrap-field {
          flex-wrap: wrap;
          .contact-row {
            width: 100%;
          }
        }
      }
    }
  }
}