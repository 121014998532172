@import '../../common/styles/font-size';

.not-found-page {
  max-width: 897px;
  padding: 0px 16px;
  margin: 100px auto auto auto;
  position: relative;
  .not-found-description {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 10%;
    padding: 0px 16px;

    & > h1 {
      @include text-42-fw-700;
    }
    & > p {
      @include text-16-fw-400;
    }
    .not-found-wrap-btn {
      @include text-16-fw-700;
      width: 100%;
      text-align: center;
      & > button {
        margin-right: 12px;
        padding: 8px 16px;
        background: transparent;
        border: 1px solid #667085;
        border-radius: 4px;
        &:hover {
          background-color: #ffffff;
          color: #EA2127;
          border: 1px solid #EA2127;
        }
      }
     & > a {
      & > button {
        background-color: #EA2127;
        color: #ffffff;
        padding: 8px 16px;
        border: 1px solid #ffffff;
        border-radius: 4px;
        &:hover {
          background-color: #ffffff;
          color: #EA2127;
          border: 1px solid #EA2127;
        }
      }
     }
    }
  }
  
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .not-found-page {
    .not-found-description {
      bottom: 0;
      & > h1 {
        @include text-26-fw-700;
      }
      & > p {
        @include text-14-fw-400;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .not-found-page {
    & > img {
      margin-bottom: 120px;
    }
    .not-found-description {
      & > h1 {
        @include text-26-fw-700;
      }
      & > p {
        @include text-14-fw-400;
      }
    }
  }
}