@import "../../../../common/styles/font-size";
// home-service
.m-home-service {
  margin: auto 16px;
  & > h1 {
    text-align: center;
    @include text-26-fw-700;
  }
  .service-warp {
    text-align: center;
  }
}
