@import '../../../../common/styles/font-size';

// home-community
.home-community{
  background: #F2F2F2;
  padding: 160px 0px 92px 0px;
  .w-1440 {
    .community-left{
      margin: auto 0;
      & > h3{
        @include text-24-fw-600;
      }
      & > h2{
        @include text-44-fw-700;
      }
      & >p {
        @include text-16-fw-400;
      }
      .community-btn {
        @include text-16-fw-700;
        background-color: #EA2127;
        color: #ffffff;
        padding: 14px 16px;
        border: 1px solid #ffffff;
        border-radius: 4px;
        &:hover {
          background: #ffffff;
          color: #EA2127;
          border: 1px solid #EA2127;
        }
      }
    }
    .community-right {
      // .community-right-image{
      //   max-width: 578px;
      //   position: relative;
      //   left: 170px;
      // }
      // .community-logo {
      //   background-color: #ffffff;
      //   padding: 20px;
      //   position: relative;
      //   right: -87px;
      //   bottom: -16px;
      //   box-shadow: 0px 0px 12px #D0D5DD;
      //   border-radius: 4px;
      // }
      // .community-video {
      //   position: relative;
      //   bottom: -120px;
      //   right: 120px;
      //   filter: drop-shadow(0px 0px 12px #98A2B3);
      //   .community-icon {
      //     width: 42px;
      //     height: 40px;
      //     position: absolute;
      //     top: 50%;
      //     left: 50%;
      //     transform: translate(-50%, -50%);
      //   }
      // }
    }
  }
  
}