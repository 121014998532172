@import "../../../../common/styles/font-size";
// home-introduction
.m-home-introduction {
  padding: 80px 0px;
  margin: auto 16px;
  display: flex;
  flex-direction: column-reverse;
  .introduction-text {
    margin: auto 0;
    & > h1 {
      @include text-26-fw-700;
    }
    & > p {
      @include text-16-fw-400;
    }
  }
  .introduction-video {
    border-radius: 8px;
    .introduction-anime {
      position: relative;
      .introduction-img {
        width: 100%;
        border-radius: 8px;
      }
      .introduction-icon {
        width: 42px;
        height: 42px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

/*Tablet styles*/
