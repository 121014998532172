@import '../../../../common/styles/font-size';
.home-why{
  // background-color: #F2F4F7;
  padding: 84px 0px 169px 0px;
  .w-1440 {
    & > h2{
      text-align: center;
      @include text-42-fw-700;
    }
    .row{
      display: flex;
      justify-content: space-between;
      // gap: 24px;
      .why-left{
        margin: auto 0;
        .why-ordinal {
          @include text-28-fw-700;
          width: 46px;
          height: 46px;
          color: #FFFFFF;
          background: #101828;
          box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 12px -2px rgba(16, 24, 40, 0.1);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
        }
        & > h3 {
          @include text-42-fw-700;
        }
        & > p {
          @include text-16-fw-400;
        }
      }
      .why-right {
        background: url('../../../../assets/images/image_global_why_bg.png') center center no-repeat;
        background-size: 100% 100%;
        position: relative;
        svg {
          position: absolute;
          margin: auto;
          display: block;
          left: 0; 
          top: 0; 
          width: 100%; 
          height: 100%;
          padding: 5px 0px;
        }
      }
    }
  }
}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .home-why {
    .w-1440{
      & > h2{
        @include text-36-fw-700;
      }
      .why-highlights{
        .why-left {
          & > h2{
            @include text-36-fw-700;
          }
          & > p{
            @include text-14-fw-400;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact-us-page {

  }
}