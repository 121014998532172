@import '../../../../common/styles/font-size';

.ads-nav {
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 12%);
  .container-fluid {
    & > a {
      margin-right: 49px;
    }

    .nav-item {
      cursor: pointer;
      & > a {
        color: #101828 !important;
        @include text-16-fw-400;
      }

      .has-submenu {
        display: none;
        position: absolute;
        padding-top: 30px;
        width: 218px;
        z-index: 1;
        box-shadow: 0 4px 2px -2px rgb(0 0 0 / 12%);
        .nav-submenu {
          background-color: #ffffff;
          padding: 20px 0px;
          border-radius: 8px;
          & > a {
            @include text-16-fw-700;
            float: none;
            color: #101828;
            padding: 8px 10px;
            text-decoration: none;
            display: block;
            text-align: left;
            border-left: 4px solid #ffffff;
            &:hover {
              background-color: #F2F2F2;
              border-left: 4px solid #EA2127 !important;
            }
          }
        }
      }

      .has-submenu-2 {
        display: none;
        position: absolute;
        padding-top: 30px;
        /*hidden submenu right*/
        // width: 888px;
        width: 385px;
        box-shadow: 0 4px 2px -2px rgb(0 0 0 / 12%);
        z-index: 1;
        .nav-submenu-2 {
          background-color: #ffffff;
          // padding: 30px;
          border-radius: 8px;
          display: flex;
          .nav-submenu-left {
            /*hidden submenu right*/
            // width: 40%;
            width: 100%;
            padding: 20px 30px 20px 30px;
            & > p {
              @include text-16-fw-500;
              color: #667085;
            }
            & > a {
              text-decoration: none;
              color: #101828;
              .submenu-item-product {
                display: flex;
                gap: 12px;
                align-items: center;
                border-left: 4px solid #ffffff;
                .product-icon {
                  padding-left: 8px;
                  max-width: 60px;
                  svg {
                    font-size: 36px;
                  }
                }
                .product-description {
                  & > p {
                    text-decoration: none;
                    @include text-16-fw-700;
                  }
                  
                  & > span {
                    @include text-13-fw-400;
                  }
                }
                &:hover {
                  background-color: #F2F2F2;
                  border-left: 4px solid #EA2127 !important;
                }
              }
            }
          }
          .nav-submenu-right {
            width: 60%;
            background: #F9FAFB;
            padding: 20px 30px 20px 20px;
            & > p {
              @include text-16-fw-500;
              color: #667085;
            }
            .submenu-solution-wrap {
              display: flex;
              gap: 20px;
              .solution-description {
                width: 50%;
                & > p {
                  @include text-16-fw-700;
                  &:hover{
                    color: #EA2127;
                    cursor: pointer;
                  }
                }
                & > span {
                  @include text-14-fw-400;
                }
              }
            }
          }
        }
      }

      &:hover {
        .has-submenu {
          display: block;
        }
        .has-submenu-2 {
          display: block;
        }
      }
    }

    .nav-left {
      .navbar-nav {
        gap: 16px;
      }
    }

    .nav-right {
      &> p {
        color: #F27327;
        @include text-14-fw-400;
        font-style: italic;
        vertical-align: middle;
      }
      .divider-vertical {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        font-size: 16px;
        padding-right: 10px;
        margin-right: 10px;
        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
          width: 1px;
          height: 14px;
          background: #667085;
        }
      }
    }
  }
}

.layout-fixed {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 9000;
}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .ads-nav {
   .container-fluid {
      .nav-item {
        .has-submenu-2 {
          /*hidden submenu right*/
          // left: 60px;
        }
      }
   }
}
}

/** Mobile*/
@media screen and (min-width: 320px) and (max-width: 991px) {
  .ads-nav {
    .offcanvas-end {
      border: none;
    }
    .offcanvas-header {
      padding: 12px 16px;
    }
    .offcanvas-body {
      position: relative;
      padding: 0px 16px;
    }
    
  }
}