.modalX {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 9001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; 
  background-color: rgba($color: #000000, $alpha: 0.4);
  transition: 0.3s ease-in-out;

  &.active {
      opacity: 1;
      visibility: visible;
  }

  &__content {
    // max-width: 80vw;
      max-width: 960px;
      height: 450px;
      margin: 50px auto;
      border-radius: 5px;
      position: relative;
      transition: inherit;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-100px);
  }

  &.active &__content {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
  }

  &__body {
    position: relative;
    max-width: 80vw;
    padding-top: 56.25%;
    margin: auto;
    & > iframe {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%!important;
        height: 100%!important;
    }
  }

  &__header,
  &__body,
  &__footer {
      // padding: 16px 20px;
  }

  &__close {
      position: absolute;
      top: -40px;
      right: 0;
      font-size: 1.5rem;
      cursor: pointer;
      color: #ffffff;

      &:hover {
          color: #000;
      }
  }
}