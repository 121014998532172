@import '../../../../common/styles/font-size';


.ads-evaluate {
  background: no-repeat url(../../../../assets/images/ads/image_bg_community.png);
  background-size: cover;
  width: 100%;
  .ads-evaluate-container {
    max-width: 1172px;
    margin: 0px auto;
    padding: 80px 16px;
    & > p {
      @include text-40-fw-700;
      color: #ffffff;
    }
    .ads-evaluate-slide {
      .evaluate-angle {
        display: flex;
        gap: 6px;
        .angle-icon{
          background: rgba(255, 255, 255, 0.1);
          border-radius: 50%;
          padding: 10px 14px 10px 13px;
          cursor: pointer;
          color: #ffffff;
          &:hover {
            background: #EA2127;
          }
        }
      }
      .evaluate-slide-content {
        .slick-list {
          // .slick-track {
          //   .slick-active {
          //     max-width: 773px !important;
          //   }
          // }
          padding:0 !important;
          // height: 745px !important;
          .slick-center {
            .feedback-item {
              color: white;
              // margin-right: 30px;
              // max-width: 743px !important;
              .feedback-content { 
                .feedback-btn {
                  color: white;
                }
              }
            }
          }
        }
       
    
        .feedback-item{
          background: rgba(255, 255, 255, 0.1);
          backdrop-filter: blur(16px);
          display: flex !important;
          border-radius: 8px;
          // padding: 16px;
          .feedback-logo-customer{
            & > img {
              max-width: 266px;
              border-radius: 4px;
            }
          }
          .feedback-content {
            color: #ffffff;
            padding: 20px 46px 0px 46px;
            .feedback-content-img {
              max-width: 112px;
              margin-bottom: 40px;
              background-color: #ffffff;
              padding: 15px;
              border-radius: 8px;
            }
            .feedback-content-name {
              margin-top: 24px;
              margin-bottom: 24px;
              @include text-16-fw-250;
            }
            .feedback-content-header {
              @include text-20-fw-700;
              margin-bottom: 4px;
            }
            .feedback-content-comment {
              @include text-16-fw-250;
            }

          }
        }
      }
    }
  }
}