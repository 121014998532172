@import '../../../../common/styles/font-size';

.about-client{
  margin: 80px auto 80px auto;
  max-width: 1172px;
  padding: 0 16px 0px 16px;
  text-align: center;
  .client-title {
    margin-bottom: 60px;
    & > h2 {
      @include text-42-fw-700;
    }
    & > p {
      @include text-16-fw-400;
    }
    
  }
  .client-image-container {
    margin: auto;
    max-width: 936px;
    padding: 0px 16px;
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            & > div {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .slick-dots {
        & > li {
          margin-right: 8px;
          & > button {
            width: 20px;
            height: 4px;
            background-color: #d9d9d9;
            border-radius: 4px;
            padding: 0;
            &::before {
              display: none;
            }
          }
        }
        .slick-active {
          & > button {
            background: #ea2127;
          }
        }
      }
    }
    .client-logo {
      max-width: 144px;
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 991px) {
  .about-client{
    .client-title {
      margin-bottom: 60px;
      & > h2 {
        @include text-26-fw-700;
      }
    }
    .client-image-container {
      max-width: 653px;
      margin: auto;
      .row {
        gap: 30px;
        // .client-logo {
        //   max-width: 57px;
        // }
      }
      
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 576px) {
  .about-client{
    .client-image-container {
      max-width: 377px;
    }
  }
}