@import "../../../../common/styles/font-size";

.download-studio {
  margin-bottom: 56px;
  & > h1 {
    @include text-42-fw-700;
    text-align: center;
  }
  .download-os-head {
    display: flex;
    text-align: center;
    border-bottom: 2px solid #344054;
    .os-head-left,
    .os-head-right {
      width: 50%;
      padding: 10px;
      background: #f2f2f2;
      & > p {
        @include text-28-fw-600;
      }
      & > span {
        @include text-14-fw-600;
      }
      &:hover {
        background: #ea212785;
        color: #ffffff;
      }
    }
    .os-head-active {
      background: #ea2127 !important;
      color: #ffffff;
    }
  }
  .download-os-container {
    display: flex;
    // gap: 25px;
    .download-os {
      width: 50%;
      padding: 20px;
      background: #f2f2f2;
      text-align: center;
      text-decoration: none;

      // &:hover {
      //   background: #f2f2f2;
      // }
      & > p {
        margin-top: 20px;
        @include text-20-fw-700;
        color: #101828;
      }
      .download-wrapper-btn {
        // select {
        //   padding: 8px 12px;
        //   border: 1px solid #d0d5dd;
        //   border-radius: 4px;
        //   transition: border-color 0.15s ease-in-out,
        //     box-shadow 0.15s ease-in-out;
        //   outline: none;
        //   width: 140px;
        //   height: 40px;
        //   -webkit-appearance: none;
        //   -moz-appearance: none;
        // }

        .download-btn:nth-child(1) {
          margin-right: 20px;
        }
        a:has(button:disabled) {
          pointer-events: none;
        }
      }
      .download-btn {
        // margin-left: 20px;
        @include text-16-fw-700;
        color: #ffffff;
        border: 1px solid #ea2127;
        background: #ea2127;
        border-radius: 4px;
        padding: 8px 20px;
        &:hover {
          background: #ffffff;
          color: #ea2127;
          border: 1px solid #ea2127;
        }
      }
      .download-btn-disabled {
        background: #ccc;
        border: none;
      }
    }
    .download-os-bg-current {
      background: #dadada;
    }
    // .download-os-mac {
    //   & > p {
    //     margin-top: 8px;
    //     @include text-22-fw-600;
    //   }
    // }
  }
  .download-os-requirement {
    & > p {
      @include text-16-fw-600;
      & > a {
        text-decoration: none;
      }
    }
    .requirement-item {
      display: flex;
      padding: 20px 0px 20px 34px;
      background: #f2f2f2;
      font-family: var(--font-archia);
      .requirement-item-left {
        width: 20%;
        min-width: 100px;
        @include text-16-fw-600;
      }
      .requirement-item-right {
        flex: 1;
        @include text-16-fw-250;
      }
    }
    .bold-text {
      font-weight: 600 !important;
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 1200px) {
  .download-os-container {
    flex-wrap: wrap;
    .download-os .download-wrapper-btn {
      & > a:nth-child(1) .download-btn {
        margin-right: 0px;
        margin-bottom: 20px;
        padding: 8px 40px;
      }
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 768px) {
  .download-studio {
    & > h1 {
      @include text-26-fw-700;
      text-align: center;
    }
    .download-os-container {
      flex-wrap: wrap;
      .download-os {
        width: 100%;
        // .download-choose-version {
        //   & > select {
        //     display: block;
        //     margin: auto auto 20px auto;
        //   }
        // }
        .download-btn {
          margin-left: 0px;
        }
      }
    }
    .download-os-requirement {
      .requirement-item {
        padding: 20px 10px 20px 10px;
      }
    }
  }
}
