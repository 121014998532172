@import "../../common/styles/font-size";
// pricing-started
.m-pricing-started {
  background: url("../../assets/images/mobile_image_product.png") center center
    no-repeat;
  background-size: cover;
  padding: 60px 16px;
  .w-375 {
    max-width: 375px;
    margin: auto;
    & > h1 {
      color: #ffffff;
      text-align: center;
      @include text-26-fw-700;
    }
    & > p {
      color: #ffffff;
      text-align: center;
      @include text-18-fw-400;
    }

    .pricing-tab {
      .tab-left {
        width: fit-content;
        margin: auto;
        background: #ffffff;
        box-shadow: 0px 8px 16px rgba(238, 238, 238, 0.12);
        border-radius: 30px;
        .tab-item {
          display: table-cell;
          vertical-align: middle;
          @include text-14-fw-400;
          width: fit-content;
          text-align: center;
          cursor: pointer;
          padding: 8px 18px;
        }
        .tab-active {
          background: linear-gradient(
            90deg,
            rgba(1, 201, 236, 0.98) 57.5%,
            #00e06c 117.45%
          );
          border-radius: 60px;
          color: #ffffff;
        }
      }
    }

    .product {
      background-color: #ffffff;
      border-radius: 12px;
      padding: 70px 16px;
      .product-header {
        text-align: center;
        & > span {
          display: inline-block;
          background: #f2f2f2;
          padding: 20px;
          border-radius: 8px;
          svg {
            font-size: 45px;
          }
        }
        .product-tag {
          margin: auto;
          @include text-16-fw-600;
          width: fit-content;
          padding: 4px 12px;
          border-radius: 32px;
          background: #f2f2f2;
        }
        & > h2 {
          @include text-28-fw-700;
        }
        & > p {
          @include text-16-fw-600;
          & > span {
            font-family: var(--font-archia) !important;
            @include text-28-fw-700;
            color: #ec5357;
          }
          .color-quick-silver {
            @include text-16-fw-600;
            color: #a5a5a5;
          }
        }
      }
      .product-content {
        margin-top: 40px;
        .product-benefit {
          @include text-16-fw-400;
          display: flex;
          gap: 16px;
          & > p > span {
            color: #ec5357;
            @include text-20-fw-700;
          }
        }
      }
      .wrap-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        & > a {
          width: 93%;
          .product-btn {
            width: 100%;
            @include text-16-fw-700;
            background-color: #ea2127;
            color: #ffffff;
            padding: 8px 12px;
            border: 1px solid #ea2127;
            border-radius: 4px;
            &:hover {
              background: #ffffff;
              color: #ea2127;
              border: 1px solid #ea2127;
            }
          }
        }
      }
    }
  }
}
