@import '../../../../common/styles/font-size';


.ads-footer-cta {
  background: #101828;
  color: #ffffff;
  padding: 0px 70px;
  .footer-started{
    margin: 0px auto 0px auto;
    max-width: 1150px;
    position: relative;
    // text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: end;
    background: url('../../../../assets/images/ads/image_bg_footer.png') center center no-repeat;
    background-size: cover;
    border-radius: 16px;
    bottom: 72px;
    padding: 40px;
    .footer-started-content {
      & > h3 {
        @include  text-36-fw-700;
      }
      & > p {
        @include  text-20-fw-400;
      }
    }
   
    button {
      @include  text-16-fw-400;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #ffffff;
      padding: 14px 12px;
      color: #EA2127;
      &:hover {
        background: #FFE0E1;
        color: #EA2127;
        border: 1px solid #EA2127;
      }
    }
  }
}


.ads-footer {
  position: relative;
  background: #101828;
  color: #ffffff;
  padding: 0px 0px 31px 0px;
  margin: auto;
  .footer-text-14{
    @include text-14-fw-400;
  }
  .footer-text-22{
    @include text-22-fw-600;
  }


  .footer-warp{
    display: flex;  
    gap: 24px;
    .footer-started-info{
      width: 301px;
    }
    .footer-link {
      width: 175px;
      & > p {
        @include text-22-fw-600;
      }
      & > ul {
        list-style-type: none;
        padding-left: 0px;
        @include text-14-fw-400;
        & > a {
          text-decoration: none;
          color: #ffffff;
          &> :hover {
            text-decoration: underline;
          }
        }
      }
      .footer-social {
        & > a {
          color: #ffffff;
        }
      }
      .footer-under-social {
        & > a {
          & > img:first-child {
            width: 47px;
          }
          & > img {
            width: 37px;
          }
        }
      }
    }
  }
  .text-center {
    @include text-12-fw-400;
  }
}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .ads-footer {
      .w-1440 {
        .footer-warp {
          .footer-started-info {
            & > h4 {
              @include text-18-fw-600;
            }
            & > p {
              @include text-14-fw-400;
            }
            .footer-social {
              & > h4 {
                @include text-18-fw-600;
              }
            }
          }
          .footer-link {
            & > p {
              @include text-18-fw-600;
            }
            li {
              @include text-12-fw-400;
            }
          }
        }
      }
  }
}

/** Mobile*/
@media screen and (min-width: 320px) and (max-width: 991px) {
  .ads-footer-cta {
    padding: 0px 16px;
    .footer-started {
      background: url('../../../../assets/images/ads/mobile_bg_footer.png') center center no-repeat;
      background-size: cover;
      // bottom: 131px;
      flex-direction: column;
      align-items: baseline;
      padding: 16px 16px 0px 16px;
      .footer-started-content {
        & > h3 {
          @include text-18-fw-700;
          color: white;
        }
        & > p {
          @include text-16-fw-400;
        }
      }
      & > a {
        width: 100%;
        button {
          margin-top: 16px;
          padding: 10px 12px;
          border: none;
          width: 100%;
        }
      }
    }
  }
  .ads-footer {
    padding: 0px 16px 31px 16px;
    .w-1440 {
      max-width: 991px;
      .footer-warp {
        gap: 40px;
        flex-wrap: wrap;
        .footer-started-info {
          width: 100%;
        }
        .footer-link {
          width: 151px;
        }
      }
    }
  }
}

/** Mobile*/
@media screen and (min-width: 320px) and (max-width: 768px) {
  .ads-footer {
    padding: 0px 16px 31px 16px;
    .w-1440 {
      max-width: 991px;
    }
  }
}