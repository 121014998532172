@import '../../../../common/styles/font-size';

.download-modal-ask-login {
  text-align: center;
  .modalX__content {
    margin: 13% auto;
    .modalX__body {
      background-color: #ffffff;
      border-radius: 8px;
      max-width: 30vw;
      min-width: 282px;
      padding: 24px 24px;
      & >  p {
        margin-top: 20px;
        @include text-16-fw-600;
      }
      .login-btn {
        margin-left: 10px;
        @include text-16-fw-700;
        background-color: #EA2127;
        color: #ffffff;
        padding: 5px 24px;
        border: 1px solid #ffffff;
        border-radius: 4px;
        &:hover {
          background: #ffffff;
          color: #EA2127;
          border: 1px solid #EA2127;
        }
      }
      .skip-btn {
        margin-left: 10px;
        @include text-16-fw-700;
        background-color:  #ffffff;
        color: #EA2127;
        padding: 5px 31px;
        border: 1px solid #EA2127;
        border-radius: 4px;
        &:hover {
          background: #EA2127;
          color: #ffffff;
          border: 1px solid #EA2127;
        }
      }
    }
  }
  
}