@import '../../../common/styles/font-size';


.m-ads-evaluate {
  background: no-repeat url(../../../assets/images/ads/mobile_bg_evaluate.png);
  background-size: cover;
  width: 100%;
  .ads-evaluate-container {
    max-width: 1172px;
    margin: 0px auto;
    padding: 32px 16px;
    & > p {
      @include text-24-fw-700;
      color: #ffffff;
    }
    .ads-evaluate-customer {
      .evaluate-customer-item {
        &:first-child {
          margin-bottom: 24px;
        }
        .customer-image {
          background: #ffffff;
          border-radius: 8px 8px 0px 0px;

          & > img {
            border-top-right-radius: 8px;
          } 
        }
        .customer-reviews {
          color: #ffffff;
          background: rgba(255, 255, 255, 0.1);
          padding: 24px 20px;
          border-radius: 0px 0px 8px 8px;
          .customer-reviews-logo {
            max-width: 101px;
            margin-bottom: 16px;
            background-color: #ffffff;
            padding: 15px 24px;
            border-radius: 8px;
          }
          .feedback-content-title {
            @include text-18-fw-700;
          }
          .feedback-content-description {
            @include text-16-fw-400;
          }
        }
      }
    }
   
  }
}