@import '../../../../common/styles/font-size';

// home-community
.m-home-community{
  background: #F2F2F2;
  padding: 80px 0px;
  
  .community-above {
      text-align: center;
  }

  .community-below {
    text-align: center;
    & > h3{
      @include text-16-fw-700;
    }
    & > h2{
      @include text-28-fw-700;
    }
    & >p {
      @include text-16-fw-400;
    }
    .community-btn {
      @include text-16-fw-700;
      background-color: #EA2127;
      color: #ffffff;
      padding: 14px 16px;
      border: 1px solid #ffffff;
      border-radius: 4px;
      &:hover {
        background: #ffffff;
        color: #EA2127;
        border: 1px solid #EA2127;
      }
    }
  }
  
}