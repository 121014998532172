@import '../../../../common/styles/font-size';
// m-test-engine-feature
.m-test-engine-feature {
  // padding: 0px 72px 140px;
  overflow: hidden;
  padding-bottom: 9px;
  margin-bottom: 60px;
    .recognition-text {
      margin-top: 60px;
      text-align: center;
      & > h2 {
        @include text-26-fw-700;
        & > span {
          color: #EA2127;
        }
      }
    }
    .recognition-slides {
      .slick-slider {
        .slick-list {
          .slick-track {
            .slick-slide {
              & > div {
                display: flex;
                justify-content: center;
                
              }
            }
          }
        }
        .slick-dots {
          & > li {
            margin-right: 8px;
            & >  button {
              width: 20px;
              height: 4px;
              background-color: #D9D9D9;
              border-radius: 4px;
              padding: 0;
              &::before {
                display: none;
              }
            }
          }
          .slick-active {
            & >  button {
              background: #EA2127;
            }
            
          }
        }
      }
     
        .recognition-row {
          max-width: 280px;
          height: 320px;
          padding: 20px 16px;
          box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
          border-radius: 8px;
          .recognition-logo{
            width: 66px;
          }
          & > h3 {
            @include text-20-fw-700;
          }
          & > p {
            @include text-16-fw-400;
          }
        }
      
    }
  
}