@import '../../../../common/styles/font-size'; 
// home-service
.home-service {
  margin: 30px 0px 90px 0px;
  .w-1440 {
    & > h2 {
      text-align: center;
      @include text-42-fw-700;
    }
  }
  .service-warp {
    display: flex;
    position: relative;
    overflow: hidden;
    height: auto;
    width: 100%;
    padding-bottom: 47%;
    background: url('../../../../assets/images/image_services.png') center center no-repeat;
    background-size: 100%;
    // &::after {
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: url('../../../../assets/images/image_services.png') center center no-repeat;
    //   background-size: 100%
    // }
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 160%;
      height: 160%;
      transform: translate(-50%, -50%);
      background: url('../../../../assets/images/shadow_image_services.png') center center no-repeat;
      background-size: contain;
    }
    .service-image{
      width: 100%;
    }
    .service-product {
      // hover
      position: relative;
      overflow: visible;
      transition: transform .2s;

      & > .service-bg-red {
        background: #cf1016;
        border-radius: 50%;
        width: 85%;
        height: 85%;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      
      &:hover{
        -ms-transform: scale(1.05);
        -webkit-transform: scale(1.05);
        transform: scale(1.05); 
        .txt-label {
          transform: translate(0, calc(-100% - 2px));
        }
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // background: #fff;
        z-index: 2;
        border-radius: 8px;
      }
      > * {
        position: relative;
        z-index: 3;
      }
      // .txt-label {
      //   @include text-16-fw-600;
      //   text-align: center;
      //   position: absolute;
      //   right: 10px;
      //   top: 0;
      //   height: 0;
      //   transform: translate(0, 0);
      //   color: #fff;
      //   transition: all 0.4s ease;
      //   z-index: -1;
      //   border-bottom: 25px solid #EA2127;
	    //   border-left: 5px solid transparent;
	    //   border-right: 5px solid transparent;
	    //   width: 117px;

      // }
    }

    .service-product-studio,
    .service-product-devops,
    .service-product-device-farm,
    .service-product-cicd {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      border: double 2px transparent;
      border-radius: 8px;
      background-image: linear-gradient(white, white), linear-gradient(to right, #FFF3F4, #EA212780);
      // color: #ffffff;        
      background-origin: border-box;
      background-clip: content-box, border-box;
    }

    .service-product-devops,
    .service-product-device-farm {
      width: 20.53%;
      height: 43.7%;
      border-radius: 50%;
      text-align: center;
      p {
        @include text-22-fw-600;
      }
    }
    
    .service-product-studio {
      width: 20.53%;
      height: 43.7%;
      border-radius: 50%; 
      top: 28%;
      left: 25%;
      p {
        @include text-22-fw-600;
      }
    }
    .service-product-devops {
      top: 27%;
      right: 1.5%;
    }
    .service-product-device-farm {
      bottom: 24%;
      right: 9.5%;
    }

    .service-product-cicd {
      width: 18.6%;
      height: 39.6%;
      border-radius: 50%;
      top: 30.5%;
      right: 29%;
      color: #101828;
      & > h3 {
        @include text-20-fw-700;
      }
      &:hover{
        transform: scale(1.05); 
        .icon-anime {
          transform: translate(0, 10px);
        }
      }
      .icon-anime {
        @include text-16-fw-600;
        text-align: center;
        position: absolute;
        bottom: -20px;
        transform: translate(0, 0);
        color: #fff;
        transition: all 0.4s ease;
        z-index: 1;

        border-bottom: 5px solid;
	      // width: 117px;

      }
    } 
  }

}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .home-service {
    .w-1440 {
      & > h1 {
        @include text-36-fw-700;
        margin-bottom: unset !important;
      }
    }
    .service-warp {
      .service-product-devops,
        .service-product-device-farm { 
          p {
            @include text-20-fw-600;
          }
    }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .home-service {
    // margin: auto 20px;
  }
}