@import 'common';

/************************************************* Text 72px *********************************************/
@mixin text-72($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 72px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-72-fw-600 {
  @include text-72(600, 90px, calculateRem(-0.02rem));
}
/************************************************* Text 60px *********************************************/
@mixin text-60($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 60px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-60-fw-700 {
  @include text-60(700, 72px, calculateRem(-0.02rem));
}
/************************************************* Text 48px *********************************************/
@mixin text-48($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 48px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-48-fw-700 {
  @include text-48(700, 60px, calculateRem(-0.01rem));
}
@mixin text-48-fw-400 {
  @include text-48(400, 75px, calculateRem(-0.02rem));
}
/************************************************* Text 46px *********************************************/
@mixin text-46($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 46px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-46-fw-700 {
  @include text-46(700, 55px, calculateRem(-0.01rem));
}

/************************************************* Text 44px *********************************************/
@mixin text-44($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 44px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-44-fw-700 {
  @include text-44(700, 55px, calculateRem(-0.01rem));
}

/************************************************* Text 42px *********************************************/
@mixin text-42($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 42px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-42-fw-700 {
  @include text-42(700, 48px, calculateRem(-0.01rem));
}

/************************************************* Text 40px *********************************************/
@mixin text-40($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 40px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-40-fw-700 {
  @include text-40(700, 48px, calculateRem(-0.01rem));
}

/************************************************* Text 36px *********************************************/
@mixin text-36($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 36px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-36-fw-600 {
  @include text-36(600, 44px, calculateRem(-0.01rem));
}

@mixin text-36-fw-700 {
  @include text-36(700, 44px, calculateRem(-0.01rem));
}

/************************************************* Text 32px *********************************************/
@mixin text-32($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 32px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-32-fw-600 {
  @include text-32(600, 40px, calculateRem(-0.01rem));
}

@mixin text-32-fw-700 {
  @include text-32(700, 40px, calculateRem(-0.01rem));
}

/************************************************* Text 28px *********************************************/
@mixin text-28($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 28px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-28-fw-700 {
  @include text-28(700, 36px, calculateRem(-0.01rem));
}
@mixin text-28-fw-600 {
  @include text-28(600, 36px, calculateRem(-0.01rem));
}

/************************************************* Text 26px *********************************************/
@mixin text-26($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 26px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-26-fw-700 {
  @include text-26(700, 32px, calculateRem(0.005rem));
}

/************************************************* Text 24px *********************************************/
@mixin text-24($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 24px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-24-fw-600 {
  @include text-24(600, 32px, calculateRem(0.005rem));
}

@mixin text-24-fw-700 {
  @include text-24(700, 32px, calculateRem(0.005rem));
}

/************************************************* Text 22px *********************************************/
@mixin text-22($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 22px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-22-fw-600 {
  @include text-22(600, 28px, calculateRem(0.005rem));
}

/************************************************* Text 20px *********************************************/
@mixin text-20($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 20px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-20-fw-700 {
  @include text-20(700, 24px, calculateRem(0.005rem));
}

@mixin text-20-fw-600 {
  @include text-20(600, 24px, calculateRem(0.005rem));
}

@mixin text-20-fw-500 {
  @include text-20(500, 24px, calculateRem(0.005rem));
}

@mixin text-20-fw-400 {
  @include text-20(400, 28px, calculateRem(0.01rem));
}

/************************************************* Text 18px *********************************************/
@mixin text-18($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 18px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-18-fw-700 {
  @include text-18(700, 28px, calculateRem(0.01rem));
}

@mixin text-18-fw-600 {
  @include text-18(600, 24px, calculateRem(0.01rem));
}
@mixin text-18-fw-400 {
  @include text-18(400, 24px, calculateRem(0.01rem));
}

@mixin text-18-fw-300 {
  @include text-18(300, 24px, calculateRem(0.01rem));
}

/************************************************* Text 16px *********************************************/
@mixin text-16($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 16px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-16-fw-100 {
  @include text-16(100, 24px, calculateRem(0.01rem));
}

@mixin text-16-fw-600 {
  @include text-16(600, 24px, calculateRem(0.01rem));
}

@mixin text-16-fw-700 {
  @include text-16(700, 24px, calculateRem(0.01rem));
}

@mixin text-16-fw-500 {
  @include text-16(500, 24px, calculateRem(0.005rem));
}

@mixin text-16-fw-400 {
  @include text-16(400, 24px, calculateRem(0.01rem));
}

@mixin text-16-fw-250 {
  @include text-16(250, 28px, calculateRem(0.05rem));
}

/************************************************* Text 14px *********************************************/
@mixin text-14($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 14px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-14-fw-700 {
  @include text-14(700, 20px, calculateRem(0.01rem));
}

@mixin text-14-fw-600 {
  @include text-14(600, 20px, calculateRem(0.01rem));
}

@mixin text-14-fw-400 {
  @include text-14(400, 20px, calculateRem(0.02rem));
}

/************************************************* Text 13px *********************************************/
@mixin text-13($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 13px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-13-fw-700 {
  @include text-13(700, 20px, calculateRem(0.01rem));
}

@mixin text-13-fw-400 {
  @include text-13(400, 20px, calculateRem(0.02rem));
}

/************************************************* Text 12px *********************************************/
@mixin text-12($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 12px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin text-12-fw-700 {
  @include text-12(700, 20px, calculateRem(0.01rem));
}

@mixin text-12-fw-400 {
  @include text-12(400, 16px, calculateRem(0.015rem));
}

@mixin text-12-fw-500 {
  @include text-12(500, 20px, calculateRem(0.01rem));
}

/************************************************* Text 10px *********************************************/
@mixin text-10($fontWeight, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: 10px;
  line-height: $lineHeight;
  font-style: normal;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}


@mixin text-10-fw-400 {
  @include text-10(400, 18px, calculateRem(0.0125rem));
}